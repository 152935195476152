html,
body {
  font-size: 15px !important;
}

/* New Order */
.form-order {
  background-color: #f8fbfd;
}

.flex-none {
  flex: none !important;
  margin-right: 5px;
  width: auto !important;
}

/* start: programmed_date new style button */
.program-button {
  margin-left: 5px !important;
  background-color: transparent;
  border-radius: 6px;
  display: inline-block;
  cursor: pointer;
  color: #777777;
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 19px !important;
  text-decoration: none;
}
.program-button:hover {
  border: 1px solid #dcdcdc !important;
}
/* end: programmed_date new style button */

.react-datepicker-wrapper .mdc-text-field__icon {
  bottom: calc(50% - 9px) !important;
}
.react-datepicker-popper {
  z-index: 10;
}

.hide {
  display: none;
}

/* trasición cards destino */
.my-node-enter,
.my-node-exit {
  opacity: 1;
}
.my-node-enter-active,
.my-node-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
/* Fin New Order */

.menu.active div {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.menu.active div:after {
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}

.menu.active div:before {
  transform: translate3d(0, -10px, 0) rotate(90deg);
}

.select-register {
  background: white;
  border-radius: 3px;
  border: 1px solid #c9ccd5;
  outline: none;
  padding: 5px 10px;
  width: 100%;
  margin: 1em 0;
}

.image-upload > input {
  display: none;
}

@media screen and (max-width: 780px) {
  .responsive-form {
    column-count: 1;
  }
  .paymentMethods {
    display: none !important;
  }
}

/* React Toggle */

.react-toggle-track {
  background: rgba(131, 142, 171, 0.5) !important;
}

.react-toggle-thumb {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: solid 0.5px rgba(0, 0, 0, 0) !important;
  background-color: #e6ebf1 !important;
}

.react-toggle--checked .react-toggle-thumb {
  background-color: #4cce92 !important;
}

/* Material input */
.mdc-text-field--dense .mdc-text-field__input {
  padding-right: 5px !important;
}

/* Material Select */
.customSelect .mdc-select {
  display: flex !important;
  flex-direction: column;
  height: auto !important;
}
.customSelect .mdc-select__dropdown-icon {
  bottom: 4px;
}
.customSelect select {
  height: 27px !important;
  padding-top: 0;
}
.mdc-select:not(.mdc-select--disabled) {
  background-color: transparent !important;
}
.mdc-select:hover::before {
  opacity: 0 !important;
}
.mdc-select__dropdown-icon {
  background: url('../../../assets/img/arrow_down.png') no-repeat center !important;
  z-index: 1;
}
/* End Material Select */

.mdc-select.mdcSummary .mdc-notched-outline.mdc-notched-outline--upgraded,
select.mdcSummary,
.mdc-text-field.mdcSummary .mdc-text-field__input {
  background-color: #fff;
}
.mdc-text-field.mdcSummary .mdc-notched-outline.mdc-notched-outline--upgraded {
  z-index: 1;
}
.map-container {
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  overflow: hidden;
  margin-top: 61px;
}

.vehicle-service-label {
  min-width: 150px;
}

.list-points {
  padding: 2.5em 2em;
}
.e-label-map {
  z-index: 0 !important;
}
.e-label-map-number {
  z-index: 0 !important;
  width: 60px !important;
  font-size: 2em !important;
}

.recap-order-container {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 2em;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 20px 0;
}

.recap-order-label {
  display: block;
  font-size: 0.8em;
}

.recap-order-element {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50%;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  padding-left: 15px;
}
.recap-order-element:last-child {
  border: none;
}
.recap-order-element:first-child {
  padding: 0;
}
.recap-order-name {
  display: block;
  text-align: left;
  color: gray;
  text-transform: capitalize;
  font-size: 0.8em;
}

.e-image-profile {
  width: 100%;
  height: 100%;
  border: 1px solid gray;
  border-radius: 50%;
}

.e-export-excel {
  color: #66dda4;
  font-weight: normal;
}

.js-filter-child {
  margin-right: 10px;
}

.js-filter-child > label {
  margin-bottom: 10px;
  text-align: left;
}

.js-filter-child .react-datepicker__input-container {
  display: inline-flex;
  width: 100%;
}

.js-filter-child .react-datepicker__input-container input {
  width: 100%;
}

.react-datepicker__input-container input,
.js-filter-child input {
  background: #fff;
  padding: 8px 10px;
  border-radius: 8px;
  border: 1px solid #c9ccd5 !important;
}

.js-filter-child select {
  background: white;
  margin: 8px;
}

.react-datepicker__close-icon {
  right: 16px !important;
}

.ReactTable .rt-table {
  overflow-y: hidden !important;
}
.ReactTable div {
  border: none !important;
  background: transparent;
}
.ReactTable p {
  margin-bottom: 0 !important;
  margin: 10px 0;
}

.u-form-login-flex {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.e-icon {
  color: black;
  margin-right: 0.5em;
  font-size: 1.5em;
  cursor: pointer;
}

.a-return-label {
  order: 1;
  margin-right: 10px;
}
.e-order-toggle {
  order: 2;
  margin-right: 30px;
}

.e-required-label {
  position: absolute;
  top: 6em;
  font-size: 0.8em;
}

#intercom-container .intercom-launcher-frame,
#intercom-container .intercom-launcher-discovery-frame {
  right: 50px !important;
}

.e-recap-title {
  padding: 1.5em 0px;
  margin-bottom: 2e;
  font-weight: bold;
  font-size: 1.4em;
}
.e-recap-container {
  padding: 25px 70px;
}

.e-input-date {
  min-width: 250px;
}

@media (min-width: 1024px) {
  .react-tabs__tab {
    margin: 0 60px 0 0 !important;
  }
}
@media (max-width: 1024px) {
  .c-service-type > div {
    flex-flow: column;
    align-items: left;
    margin-left: 3em;
  }

  .c-service-type label {
    flex-flow: row;
  }

  .c-service-type .u-element-wrap {
    display: block !important;
    width: 100% !important;
    margin: 0;
  }
  .e-container-recap-element {
    margin-bottom: 1em;
  }
}

@media (max-width: 480px) {
  .recap-order-element {
    border: none;
  }
}

.md-select-hack {
  height: 40px;
  width: 100%;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  border-bottom: 1px solid #c9ccd5;
  margin: 10px 0 15px 0;
  color: #c9ccd5;
}

.avatar {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
.tag {
  border-radius: 10px;
  border: 1px solid #635dc2;
  display: inline-block;
  font-size: 13px;
  margin-right: 3px;
  padding: 2px 7px 3px;
}

.e-exchange-points {
  transform: rotate(90deg);
  font-size: 1.5em;
}

.e-link {
  color: black;
  cursor: pointer;
}
.e-heart {
  color: #fd6d6d;
  white-space: nowrap;
}

.ReactModalPortal {
  z-index: 100;
}
.ReactModal__Content--after-open {
  width: 50vw !important;
  min-width: 300px;
  left: 50% !important;
  transform: translate(-50%);
  top: 10% !important;
  height: 80%;
}

.e-close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
}

/* end suggest*/
.react-autosuggest__suggestion-match {
  color: red;
  font-weight: bold;
}
.react-autosuggest__suggestions-container {
  display: none;
}
.react-autosuggest__container {
  position: relative;
}
.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 40px;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 0.8em;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 100;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: rgba(243, 243, 243, 0.822);
}
.rt-tr-group {
  position: relative;
}

/* animation new order */
.e-animation-box {
  animation: colorchange 1s infinite;
}

@keyframes colorchange {
  0% {
    background: transparent;
  }
  50% {
    background: white;
  }
  100% {
    background: transparent;
  }
}
/* end animation new order */

[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #bdccd4;
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}

/* Triangle hack to make tooltip look like a speech bubble */

[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #bdccd4;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: ' ';
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.e-status-tracking {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}

.e-container-status-detail {
  padding: 1em 1em 0px;
  width: 40%;
  min-width: 200px;
  margin: auto;
  flex-direction: column;
  flex-basis: 200px;
  align-items: center;
  transition: all 0.5s ease-out;
}

.leaflet-control-container .leaflet-top {
  bottom: 0;
  left: inherit;
  top: 6em;
}

.leaflet-control-container .leaflet-left {
  right: 1em;
}

.leaflet-control-container .leaflet-right {
  right: 4em;
}

.e-tracking-status:after {
  content: '';
  height: 20px;
  border: solid 1px #3dd442;
  position: absolute;
  left: 48%;
  top: 80%;
}

.e-container-tracking-detail {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 15px;
  border-top: 1px solid darkgray;
}

.e-container-route {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  padding: 0.5em;
  font-size: 0.8em;
}

.e-tracking-status-order {
  margin: 0.2em 0 0.5em 0;
  font-size: 1.2em;
}

.e-tracking-time {
  font-size: 0.8em;
}

.e-tracking-label-status {
  text-transform: capitalize;
}

.tracking-app-promotion {
  text-align: center;
}

@media (max-width: 780px) {
  .js-download-app {
    padding: 0.8em !important;
    font-size: 0.8em;
    display: inline-flex !important;
    position: relative;
  }
  .e-container-status-detail {
    padding-top: 0;
  }
}

@media (max-width: 840px) {
  .selectContainer {
    width: 100%;
  }
  .map-container {
    display: block;
    position: absolute;
  }
  .u-element-wrap {
    width: 100% !important;
  }
  .e-container-recap-element {
    display: flex;
    margin-bottom: 1em;
  }
  .e-container-recap-element .recap-order-name {
    text-align: right;
  }
  .e-recap-container {
    padding: 25px 40px;
  }
  .e-courier-avatar {
    margin-top: 0;
    border-top: none;
  }
  .e-courier-avatar-open {
    margin-top: inherit;
    border-top: 1px solid lightgray;
  }
  .e-container-img-status {
    display: none;
  }
}

.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: black !important;
  border-radius: 50% !important;
  color: white !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  box-shadow: none;
  border: none !important;
  width: 20px !important;
  font-size: 0.7em !important;
  font-weight: bold;
  margin-left: 8px !important;
}

.leaflet-tooltip:before {
  content: none !important;
}

.e-status-tracking:last-child .u-relative div:after {
  border-color: transparent;
}
.react-datepicker__close-icon::after {
  right: 16px !important;
}
.e-container-img-status {
  margin: 0.5em auto;
}

.react-tel-input .flag-dropdown.open-dropdown,
.react-tel-input .flag-dropdown.open-dropdown .selected-flag,
.react-tel-input .flag-dropdown:hover .selected-flag {
  background: transparent !important;
}

select:not(.mdc-select__native-control) {
  background: #fff;
  padding: 8px 10px;
  border-radius: 8px;
  border: 1px solid #c9ccd5 !important;
}

/* Bootstrap Multiselect */
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  float: left;
}

.btn-group.open .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  background-image: none;
  border-color: #adadad;
}

.btn-marker {
  color: #18a689;
  background-color: #f6fafd;
  border-color: #ccc;
}

.btn-marker:hover {
  color: #18a689;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-primary {
  background-color: #50e09e;
  border-color: #50e09e;
  color: #ffffff;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:active:focus,
.btn-primary:active:hover {
  background-color: #18a689;
  border-color: #18a689;
  color: #ffffff;
}
.btn-primary:active {
  background-image: none;
}
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:active {
  background-color: #1dc5a3;
  border-color: #1dc5a3;
}

button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button.dim,
a.dim {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  padding-top: 6px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  margin-bottom: 20px !important;
}
button.dim:active,
a.dim:active {
  top: 3px;
}
button.btn-primary.dim,
a.btn-primary.dim {
  box-shadow: inset 0 0 0 #16987e, 0 2px 0 0 #16987e, 0 5px 2px #999999;
}
button.btn-primary.dim:active,
a.btn-primary.dim:active {
  box-shadow: inset 0 0 0 #16987e, 0 2px 0 0 #16987e, 0 5px 3px #999999;
}
button.btn-default.dim,
a.btn-default.dim {
  box-shadow: inset 0 0 0 #b3b3b3, 0 2px 0 0 #b3b3b3, 0 5px 2px #999999;
}
button.btn-default.dim:active,
a.btn-default.dim:active {
  box-shadow: inset 0 0 0 #b3b3b3, 0 2px 0 0 #b3b3b3, 0 5px 3px #999999;
}
button.dim:before,
a.dim:before {
  font-size: 50px;
  line-height: 1em;
  font-weight: normal;
  color: #fff;
  display: block;
  padding-top: 10px;
}
button.dim:active:before,
a.dim:active:before {
  top: 7px;
  font-size: 50px;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.open > .dropdown-menu {
  display: block;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0 !important;
  margin: 2px 0 0 !important;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px !important;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333333;
  white-space: nowrap;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}

.radio input[type='radio'],
.radio-inline input[type='radio'],
.checkbox input[type='checkbox'],
.checkbox-inline input[type='checkbox'] {
  position: absolute;
  margin-top: 4px \9;
  margin-left: -20px;
}

input[type='radio'],
input[type='checkbox'] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}

.marker-label {
  background-color: black;
  border-radius: 50%;
  color: white;
  font-size: 12px;
  font-weight: bold;
  height: 30px;
  padding: 8px;
  text-align: center;
  width: 30px;
}

#menu-background {
  background: #000;
  display: none;
  height: 200%;
  left: 0;
  opacity: 0.6;
  position: fixed;
  top: 74px;
  width: 100%;
  z-index: 0;
}
