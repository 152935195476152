.fancy-toast {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.fancy-toast-icon {
  font-size: 20px;
  margin-right: 10px;
}

.fancy-toast-content {
  flex-grow: 1;
}

.fancy-toast-close {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
