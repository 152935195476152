@font-face {
  font-family: 'ProximaNova Regular';
  font-style: normal;
  font-weight: 400;
  src: url('ProximaNova-Regular.otf') format('otf');
  font-display: fallback;
}
@font-face {
  font-family: 'ProximaNova Light';
  font-style: normal;
  font-weight: 300;
  src: url('ProximaNova-Light.otf') format('otf');
  font-display: fallback;
}
@font-face {
  font-family: 'ProximaNova Thin';
  font-style: normal;
  font-weight: 300;
  src: url('ProximaNova-Thin.otf') format('otf');
  font-display: fallback;
}
@font-face {
  font-family: 'ProximaNova SemiBold';
  font-style: normal;
  font-weight: 600;
  src: url('ProximaNova-Semibold.otf') format('otf');
  font-display: fallback;
}
