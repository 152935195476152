.merchant-block {
  padding: 40px 20px;
  border-bottom: 1px solid #6e7176;
  margin-bottom: 50px;
}

.merchant-pill {
  padding: 0;
  margin: 0 0 5px 0;
  text-align: left;
  color: #53b88b;
  font-size: 12px;
  text-align: center;
}

.merchant-logo {
  color: #fff;
  font-weight: 300;
  margin: 0;
  text-align: left;
}

.merchant-nav {
  order: -1;
  flex: 0 0 14em;
  background: #474c50;
  transition: all ease 0.3s;
}

@media (min-width: 768px) {
  .merchant-nav {
    flex: 0 0 14em;
    min-height: calc(100vh - 78px);
  }
}

.merchant-menu {
  padding: 0px;
  text-align: left;
}

.merchant-menu li {
  padding: 0;
}

.merchant-button {
  border: 2px solid #635dc2;
  color: #fff;
  border-radius: 3px;
  padding: 5px 15px;
  background: #635dc2;
  float: right;
  cursor: pointer;
  margin-top: 5px;
}

.merchant-action {
  background: #66dda4;
  color: white;
  outline: none;
  cursor: pointer;
  font-size: 15px;
  margin: 1em auto;
  display: inline-block;
  padding: 0.6em 0.5em;
  border: none;
  border-radius: 5px;
  margin-right: 5px;
  min-width: 110px;
}

.merchant-action.accept {
  color: #fff;
  background: #2bb596;
  border: 2px solid #2bb596;
}

.merchant-action.cancel {
  background: #fff;
  border: 2px solid #ff4e66;
  color: #ff4e66;
}

.breadcrumb {
  float: right;
  font-size: 14px;
  color: #9c9d9f;
  padding-top: 10px;
  font-weight: 100;
}

.merchant-tag,
.gd {
  border-radius: 15px;
  margin: 2px;
  padding: 9px 8px 8px;
  background: #b6b6b6;
  float: left;
  color: #fff;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.5px;
  min-width: 60px;
  text-align: center;
}

.gd.gd-confirm {
  background: #635dc2;
}

.gd.gd-completed {
  background: #9f8eeb;
}

.gd.gd-ready {
  background: #48d695;
}

.gd.gd-cancel {
  background: #ff4e66;
}

.gd.gd-pending {
  background: #f9a521;
}

.dropzone {
  float: right;
  display: block;
  padding: 18px 20px 0 0;
  cursor: pointer;
  color: #635dc2;
}

.merchant-camera {
  background: #d8d8d8;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  display: block;
  text-align: center;
  padding: 7px 0;
  margin: 4px 0;
}

.merchant-icon-gray {
  fill: #303e47;
}

.merchant-icon-aside {
  fill: #fff;
  margin: -2px;
  transition: all ease 0.3s;
}

.merchant-icon-green {
  fill: #4ad591;
}

.merchant-icon-light {
  fill: #999999;
}

.merchant-icon-red {
  fill: #fd5260;
}

.merchant-menu .merchant-text-child {
  color: #fff;
  padding: 0 0px 0 15px;
  padding: 16px 40px 15px;
  width: 100%;
  display: flex;
  transition: all ease 0.3s;
  position: relative;
}

.merchant-icon-blue {
  fill: #635dc2;
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -3px;
}

.merchant-avatar {
  border-radius: 50%;
  width: 48px;
  height: 48px;
}

.merchant-detail-child {
  background: #fff;
  padding: 30px 50px;
  margin-right: 30px;
  border-radius: 2px;
}

.merchant-articles {
  text-align: left;
  font-weight: 100;
  font-size: 14px;
  padding-bottom: 20px;
}

.merchant-articles li {
  padding: 14px 0;
}

.merchant-articles span {
  float: right;
  text-align: right;
}

.merchant-drop {
  border-width: 2px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 5px;
  width: 100%;
  padding: 50px;
}

.merchant-drop-in {
  cursor: pointer;
  padding: 20px 60px;
  color: #adabab;
  height: 100%;
  text-align: center;
  position: absolute;
  transition: all 0.3s ease;
  border: 2px dashed #c9c9c9;
}

.merchant-drop-in .merchant-icon-gray {
  fill: #adabab;
}

.merchant-drop-in .merchant-note {
  text-align: center;
}

.merchant-icon-bell {
  fill: #303e47;
  cursor: pointer;
}

.merchant-banner {
  width: 100%;
  height: 200px;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.merchant-banner img {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.3s ease;
}

.merchant-banner:hover .merchant-drop-in {
  z-index: 9;
  background: #313e46cf;
  width: 100%;
  transition: all 0.3s ease;
}

.merchant-item {
  padding: 5px 0;
}

.merchant-nobody .rt-tbody .rt-td {
  font-size: 13px !important;
}

.merchant-nobody .ReactTable .rt-thead.-header {
  min-width: 1050px;
  background: #f7f7f7;
  box-shadow: none;
  border-bottom: 1px solid #e4e4e4 !important;
  border-top: 1px solid #e4e4e4 !important;
}

.merchant-nobody .ReactTable .rt-noData .gray-background,
.merchant-nobody .ReactTable .rt-noData {
  background: #fff !important;
}

.merchant-nobody .js-filter-child {
  border: 1px solid #ccc;
}

.a-return-label {
  margin: 20px;
}

.merchant-banner.logo {
  width: 250px;
  height: 250px;
}

.merchant-banner.logo .merchant-drop-in {
  padding: 20px 20px;
}

.merchant-option {
  padding: 20px 0;
  border-radius: 2px;
}

.merchant-option input {
  font-size: 15px;
}

.merchant-option-item {
  border-radius: 5px;
  padding: 20px 20px 30px;
  margin: 38px 0 0;
  background: #fff;
  position: relative;
}

.merchant-group {
  padding: 0;
}

.merchant-option-child {
  position: relative;
  padding: 10px 10px 0px;
  border-radius: 2px;
}

.merchant-option-child:hover {
  background: #f5f5f5;
}

.merchant-option-child .m-icon-child {
  opacity: 0;
  top: 20px;
  right: 5px;
}

.merchant-option-item .m-icon-p-child {
  opacity: 0;
  top: 10px;
  right: 10px;
}

.merchant-option-item:hover .m-icon-p-child {
  opacity: 1;
}

.merchant-option-child:hover .m-icon-child {
  opacity: 1;
}

.merchant-remove-icon {
  position: absolute;
  cursor: pointer;
}

.merchant-add-item {
  color: #635dc2;
  padding: 0px 15px 20px;
  font-size: 15px;
  cursor: pointer;
}

.merchant-preview {
  margin: 0 50px;
  border-radius: 4px;
  background: #fff;
}

.merchant-preview-header {
  background: #dedede;
  padding: 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-size: 13px;
  text-align: left;
}

.merchant-preview-header span {
  font-size: 10px;
  float: right;
  padding: 4px 0 0;
  font-weight: bold;
}

.merchant-preview-child {
  text-align: left;
  border-bottom: 1px solid #f1f1f1;
  padding: 19px 10px;
  margin: 0 15px;
}

.merchant-preview-input {
  display: inline-block;
  margin-right: 12px;
}

.merchant-preview-child span {
  float: right;
}

.link-bluelight {
  color: #5ea2f0;
  padding: 0 0 3px;
  border-bottom: 1px solid;
  display: inline-block;
}

.merchant-tabs {
  width: 400px;
}

.merchant-tabs-child,
.merchant-tabs li {
  text-transform: uppercase;
  text-transform: uppercase;
  background: #f0f2f5;
  border: 2px solid #f0f2f5;
  padding: 20px 20px;
  margin: 10px 0;
  cursor: move;
  list-style: none;
}

.merchant-tabs-child {
  border: 2px dashed #4ad591;
}

.merchant-note {
  color: #adabab;
  font-size: 14px;
  text-align: left;
  line-height: 18px;
}

.merchant-blockout {
  background: #fff;
  padding: 30px 30px 90px;
  margin-top: 20px;
}

.merchant-blockout.pd60 {
  margin-top: 60px;
}

.merchant-blockout-right {
  background: #fff;
  padding: 30px 30px 50px;
  margin-right: 20px;
  margin-top: 20px;
}

.merchant-blockout-right h4,
.merchant-blockout h4 {
  margin: 0px 0 30px;
}

.merchant-schedule-list {
  text-align: left;
  font-size: 14px;
  padding: 12px 25px;
  margin-top: 70px;
}

.merchant-schedule-list li {
  padding: 10px 0px;
  border-bottom: 2px solid #f0f2f5;
  position: relative;
  font-size: 13px;
}

.merchant-schedule-list .merchant-schedule-list-col {
  text-align: left;
  display: block;
}

.merchant-schedule-list li .merchant-error-action {
  position: absolute;
  right: 0;
  cursor: pointer;
  top: 10px;
  color: #fd5260;
  text-decoration: underline;
  font-size: 13px;
}

.merchant-card {
  padding: 30px;
  background-color: #ffffff;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
}

.merchant-form-button {
  padding: 0 15px;
}

.merchant-error-globally {
  color: #fd5260;
  font-size: 13px;
  text-align: center;
  padding: 14px;
  margin: 0 15px;
}

.block-optimize-road {
  position: absolute;
  z-index: 99;
  top: 10px;
  left: 10px;
}

.btn-blue-optimize {
  border: 2px solid #635dc2;
  color: #fff;
  border-radius: 4px;
  padding: 6px 10px 8px;
  background: #635dc2;
  float: right;
  cursor: pointer;
  margin-right: 20px;
  width: 170px;
}

.btn-blue-optimize span {
  float: right;
}

.map-container-public {
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  overflow: hidden;
}

.form-textarea {
  margin: 12px 0px !important;
  width: 100% !important;
}

.form-select select {
  height: 40px;
  width: 100%;
  padding: 0;
  background: transparent;
  border: 1px solid #c9ccd5;
  margin: 7px 0 15px 0;
}

.form-select div {
  font-size: 14px;
  color: #635dc2;
  padding: 0px 5px 0;
}

.facebook-group-button {
  margin: 11px 0;
}

.right10 {
  right: 15px !important;
}

.merchant-amount {
  width: 150px;
  height: 70px;
  display: inline-block;
  border: 1px solid #ccc;
  margin: -10px 2px 0;
  border-radius: 4px;
}

.merchant-amount p {
  margin: 10px 0 8px;
  font-size: 14px;
  color: #757575;
}

.merchant-amount div {
  font-size: 20px;
  font-weight: bold;
}

.merchant-articles li.merchant-product-item {
  padding: 5px 0 30px;
  border-bottom: 1px solid #ccc;
}

.merchant-order-items {
  padding: 10px;
  width: 100%;
  text-align: right;
  min-height: 50px;
}

#react-confirm-alert .react-confirm-alert-overlay {
  z-index: 999;
  background: rgba(0, 0, 0, 0.8);
}

#react-confirm-alert .react-confirm-alert-body {
  border-radius: 4px;
}

#react-confirm-alert .react-confirm-alert-body h1 {
  font-size: 1.5em;
}

#react-confirm-alert .react-confirm-alert-button-group > button {
  padding: 8px 18px 8px;
  border-radius: 4px;
  font-size: 15px;
  background: #635dc2;
}

#react-confirm-alert .react-confirm-alert-button-group > button:first-child {
  background: #66dda4;
}

#react-confirm-alert .react-confirm-alert-button-group > button:last-child {
  background: #ccc;
  color: #666;
}
.react-countdown-clock {
  display: inline-block;
  padding: 0;
  margin-top: -22px;
  padding-top: 11px;
  margin-left: -12px;
  margin-right: 10px;
}

.merchant-action:disabled,
.merchant-action[disabled] {
  cursor: not-allowed !important;
  opacity: 0.65 !important;
  background: #ccc;
  border: 2px solid #ccc;
  color: #fff;
}

.btn-blue-optimize {
  border: 2px solid #4cce92;
  color: #fff;
  border-radius: 4px;
  padding: 6px 10px 8px;
  background: #4cce92;
  float: right;
  cursor: pointer;
  margin-right: 20px;
  width: 170px;
}

.merchant-aside-menu {
  position: absolute;
  top: 20px;
  left: 15px;
  cursor: pointer;
  animation-duration: 3s;
  animation-name: slidein;
  animation-iteration-count: infinite;
}

.merchant-active-wip {
  flex: 0 0 5em;
  transition: all ease 0.3s;
}

.merchant-active-wip .merchant-text-st {
  display: none;
  transition: all ease 0.3s;
}

.merchant-text-st {
  padding-left: 14px;
  transition: all ease 0.3s;
}

.merchant-active-wip .merchant-menu {
  padding: 0px;
  text-align: left;
}

.merchant-active-wip .merchant-menu .merchant-text-child {
  text-align: center;
  display: flow-root;
  padding: 16px 20px 15px;
  transition: all ease 0.3s;
  position: relative;
}

.merchant-notify-number {
  background: #ff4e66;
  border-radius: 50%;
  padding: 5px 4px;
  font-size: 10px;
  position: absolute;
  top: 8px;
  right: 19px;
  width: 20px;
  height: 20px;
  text-align: center;
}

#preparation_time {
  border: 1px solid #ccc;
  width: 100%;
  height: 42px;
}

.merchant-nav-block {
  display: grid;
  margin: 30px 0;
  padding: 20px 0;
}

@media (max-width: 768px) {
  .merchant-nav,
  .merchant-aside-menu {
    display: none !important;
  }

  .merchant-tag,
  .gd {
    padding: 7px 6px 5px;
    font-size: 9px;
  }

  .merchant-action {
    padding: 0.6em 0.1em;
    min-width: 90px;
    font-size: 13px;
  }

  .merchant-nav-block a {
    margin: 0.5em auto;
    width: 100%;
  }
  .react-countdown-clock {
    display: none;
  }

  .rt-td .merchant-tag,
  .rt-td .gd {
    background: none !important;
    color: #56606d;
    font-weight: bold;
    font-size: 12px;
    text-transform: capitalize;
  }

  .merchant-detail-child {
    margin-right: 0;
    padding: 30px 20px;
  }

  .merchant-articles span {
    float: right;
    text-align: center;
  }
}

.form-horizontal .rc-time-picker-input {
  height: 40px;
  width: 100%;
  padding: 0 10px;
  background: transparent;
  border: 1px solid #c9ccd5;
  margin: 7px 0 15px 0;
  font-size: 16px;
  color: black;
}

.form-horizontal .rc-time-picker {
  width: 100%;
}

.atime-return-label {
  font-size: 14px;
  color: #635dc2;
  padding: 0px 5px 0;
  display: block;
  position: absolute;
  top: -16px;
  z-index: 99999999;
}

.product-item-tsd {
  text-align: left;
  font-size: 17px;
}

.product-item-tsd i {
  color: #48d695;
  font-style: initial;
}

.merchant-articles .options-item {
  padding: 3px 0;
  color: #999;
  font-size: 14px;
}

.product-item-tsd2 {
  text-align: right;
  font-size: 17px;
}
