.wrapper__container {
  width: 100%;
  max-width: 1240px;
  padding: 0 20px;
  margin: 0 auto;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.wrapper__container__full {
  max-width: 100%;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .wrapper__container {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1024px) {
  .wrapper__padding {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .wrapper__padding__bottom {
    padding-bottom: 65px !important;
  }
  .wrapper__padding__top {
    padding-top: 65px !important;
  }
}

@media screen and (max-width: 767px) {
  .wrapper__padding {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .wrapper__padding__bottom {
    padding-bottom: 45px !important;
  }
  .wrapper__padding__top {
    padding-top: 45px !important;
  }
}

.g--uppercase {
  text-transform: uppercase;
}

.list--none ul {
  margin: 0;
  padding: 0;
}

.list--none li {
  list-style: none;
}

ul.list--none {
  padding: 0;
}

ul.list--none li {
  list-style: none;
}

.content__styles__all {
  color: #797f80;
  font-family: 'ProximaNova-Light', sans-serif;
  line-height: 1.47;
  font-size: 15px;
}

.content__styles__all a {
  color: #323e48;
}

.content__styles__all h1,
.content__styles__all h2,
.content__styles__all h3,
.content__styles__all h4,
.content__styles__all h5,
.content__styles__all h6 {
  line-height: 1.25;
  margin: 0 0 20px 0;
}

.content__styles__all li > ul,
.content__styles__all li > ol {
  padding: 15px 0 15px 25px;
}

.content__styles__all li > ul:last-child,
.content__styles__all li > ol:last-child {
  padding-bottom: 0;
}

.content__styles__all ul {
  padding-left: 25px;
}

.content__styles__all ul li {
  margin-bottom: 8px;
}

.content__styles__all ol {
  padding-left: 25px;
}

.content__styles__all ol li {
  margin-bottom: 19px;
}

.content__styles__all iframe {
  width: 100%;
  max-width: 560px;
}

.content__styles__all img {
  max-width: 100%;
  height: auto;
}

.content__styles__all blockquote {
  background: #efefef;
  padding: 20px;
  margin: 10px 0;
}

.content__styles__all blockquote p:last-child {
  margin: 0;
}

.wrapper__blocks {
  overflow: hidden;
}

.header-home .header__wrap {
  height: 75px;
}

.header-home .header__position {
  background: #fff;
  border-bottom: solid 1px #f2f2f2;
}

.header-home .header__nav > ul > li {
  margin: 0 45px;
}

.header-home .header__nav > ul > li:last-child {
  display: none;
}

.header-contact .header__wrap {
  height: 75px;
}

.header-contact .header__position {
  background: #fff;
}

.header-contact .header__nav {
  margin-right: 60px;
}

.header-contact .header__nav > ul > li {
  margin: 0 20px;
}

.header-contact .header__wrap.header--scrolling .header__position {
  border-bottom: solid 1px #f2f2f2;
}

.header-coruier .header__position {
  background: transparent;
}

.header-coruier .header__nav {
  display: none;
}

.header-coruier .header__logo .logo-white {
  opacity: 1;
}

.header-coruier .header__logo .logo-color {
  opacity: 0;
}

.header-coruier .header__btn .button__g--green {
  background: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.header-coruier .header__btn .button__g--green span,
.header-coruier .header__btn .button__g--green input {
  color: #4cce92;
}

.header-coruier .header__countries .header__country__g--desk > a span {
  color: #fff;
}

.header-coruier .header__countries .header__country__g--desk > a:before {
  color: #fff;
}

.header-coruier .header__burger .header__burger__in span:nth-child(1) {
  border-bottom: 2px #fff solid;
}

.header-coruier .header__burger .header__burger__in span:nth-child(2) {
  border-bottom: 2px #fff solid;
}

.header-coruier .header__country__g .header__personalized__ae > ul {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.header-coruier
  .header__country__g--desk
  .header__personalized__inside
  > a:before,
.header-coruier
  .header__country__g--desk
  .header__personalized__inside
  span:before {
  color: #fff;
}

.header-coruier .header__country__g--desk .header__personalized__inside > a p,
.header-coruier .header__country__g--desk .header__personalized__inside span p {
  color: #fff;
}

.header-coruier
  .header__wrap.header--scrolling
  .header__country__g--desk
  .header__personalized__inside
  span
  p {
  color: #797f80 !important;
}

.header-coruier
  .header__wrap.header--scrolling
  .header__country__g--desk
  .header__personalized__inside
  span:before {
  color: #797f80 !important;
}

.header-coruier .header__wrap.header--scrolling .header__position {
  border-bottom: solid 1px #f2f2f2;
}

.header-coruier
  .header__wrap.header--scrolling
  .header__country__g
  .header__personalized__ae
  > ul {
  -webkit-box-shadow: 1px 4px 10px 0 #dcdcdc;
  box-shadow: 1px 4px 10px 0 #dcdcdc;
}

.header__wrap.header--scrolling .header__position {
  height: 75px;
  background: #fff;
}

.header__wrap.header--scrolling .header__logo .logo-white {
  opacity: 0;
}

.header__wrap.header--scrolling .header__logo .logo-color {
  opacity: 1;
}

.header__wrap.header--scrolling .header__btn .button__g--green {
  background: #4cce92;
}

.header__wrap.header--scrolling .header__btn .button__g--green span,
.header__wrap.header--scrolling .header__btn .button__g--green input {
  color: #fff;
}

.header__wrap.header--scrolling .header__country__g > a span {
  color: #797f80;
}

.header__wrap.header--scrolling .header__country__g > a:before {
  color: #797f80;
}

.header__wrap {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.header__position {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  z-index: 300;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.header__container {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header__logo {
  margin: 0;
  max-width: 172px;
  width: 100%;
  font-size: 0;
  position: relative;
}

.header__logo img {
  max-width: 100%;
  height: auto;
  display: block;
}

.header__logo .logo-white {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  height: auto;
  opacity: 0;
}

.header__details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header__nav > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header__nav > ul > li {
  margin: 0 16px;
}

.header__nav > ul > li > a {
  line-height: 1.28;
  letter-spacing: 0.3px;
  font-size: 15px;
  font-family: 'ProximaNova-Light', sans-serif;
  color: #797f80;
}

.header__country__g {
  position: relative;
}

.header__country__g .header__personalized__inside > a,
.header__country__g .header__personalized__inside span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0;
  padding: 11.5px 23px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

.header__country__g .header__personalized__inside > a:before,
.header__country__g .header__personalized__inside span:before {
  content: '\e939';
  font-family: 'icomoon';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  font-size: 6px;
  color: #797f80;
}

.header__country__g .header__personalized__inside > a img,
.header__country__g .header__personalized__inside span img {
  margin: 0;
  width: 20px;
  height: 20px;
  display: block;
}

.header__country__g .header__personalized__inside > a p,
.header__country__g .header__personalized__inside span p {
  display: block;
  font-size: 16px;
  margin: 0;
  margin-left: 12px;
  color: #797f80;
  font-family: 'ProximaNova-Light', sans-serif;
  line-height: 1.28;
  letter-spacing: 0.3px;
}

.header__country__g .header__personalized__ae > ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 1px 4px 10px 0 #dcdcdc;
  box-shadow: 1px 4px 10px 0 #dcdcdc;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  overflow: hidden;
}

.header__country__g .header__personalized__ae > ul > li:hover > a,
.header__country__g .header__personalized__ae > ul > li:hover span {
  background: #f8f9fb;
}

.header__country__g .header__personalized__ae > ul > li.selected > a,
.header__country__g .header__personalized__ae > ul > li.selected span {
  background: #f8f9fb;
}

.header__country__g .header__personalized__ae > ul > li > a,
.header__country__g .header__personalized__ae > ul > li span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0;
  padding: 11.5px 23px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
}

.header__country__g .header__personalized__ae > ul > li > a:hover,
.header__country__g .header__personalized__ae > ul > li span:hover {
  background: #f8f9fb;
}

.header__country__g .header__personalized__ae > ul > li > a img,
.header__country__g .header__personalized__ae > ul > li span img {
  margin: 0;
  width: 20px;
  height: 20px;
  display: block;
}

.header__country__g .header__personalized__ae > ul > li > a p,
.header__country__g .header__personalized__ae > ul > li span p {
  display: block;
  font-size: 16px;
  margin: 0;
  margin-left: 12px;
  color: #797f80;
  font-family: 'ProximaNova-Light', sans-serif;
  line-height: 1.28;
  letter-spacing: 0.3px;
}

.header__personalized--hide {
  display: none;
}

.header__burguer__wrap {
  position: fixed;
  top: 0;
  height: 75px;
  left: 0;
  z-index: 550;
  width: 100%;
  pointer-events: none;
}

.header__burguer__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  height: 100%;
}

.header__details {
  padding-right: 60px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.header__countries {
  margin-right: 40px;
  width: 120px;
}

.header__burger {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  pointer-events: auto;
  height: 100%;
}

.header__burger .header__burger__in {
  width: 25px;
}

.header__burger .header__burger__in span {
  border-radius: 2px;
}

.header__burger .header__burger__in span:nth-child(1) {
  position: relative;
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: auto;
  width: 13px;
  border-bottom: 2px #4cce92 solid;
  -webkit-transition: width 0.3s, -webkit-transform 0.2s;
  transition: width 0.3s, -webkit-transform 0.2s;
  transition: transform 0.2s, width 0.3s;
  transition: transform 0.2s, width 0.3s, -webkit-transform 0.2s;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.header__burger .header__burger__in span:nth-child(2) {
  position: relative;
  display: block;
  width: 100%;
  border-bottom: 2px #4cce92 solid;
  -webkit-transition: width 0.3s, -webkit-transform 0.2s;
  transition: width 0.3s, -webkit-transform 0.2s;
  transition: transform 0.2s, width 0.3s;
  transition: transform 0.2s, width 0.3s, -webkit-transform 0.2s;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  margin: 6px 0 0 auto;
}

.header__burger .header__burger__in:hover span:nth-child(1) {
  width: 100%;
}

.header__burger .header__burger__in:hover span:nth-child(2) {
  width: 13px;
}

.header__burger .header__burger__in.open span:nth-child(1) {
  -webkit-transform: translateY(4px) rotate(-45deg);
  transform: translateY(4px) rotate(-45deg);
  width: 25px !important;
  border-bottom: 2px #4cce92 solid;
}

.header__burger .header__burger__in.open span:nth-child(2) {
  width: 25px !important;
  border-bottom: 2px #4cce92 solid;
  -webkit-transform: translateY(-4px) rotate(45deg);
  transform: translateY(-4px) rotate(45deg);
}

.header__burger.burger--scrolling .header__burger__in span:nth-child(1) {
  border-bottom: 2px #4cce92 solid !important;
}

.header__burger.burger--scrolling .header__burger__in span:nth-child(2) {
  border-bottom: 2px #4cce92 solid !important;
}

.header__burger.burger--scrolling .header__burger__in.open span:nth-child(1) {
  border-bottom: 2px #4cce92 solid !important;
}

.header__burger.burger--scrolling .header__burger__in.open span:nth-child(2) {
  border-bottom: 2px #4cce92 solid !important;
}

.header__sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 650px;
  height: 100%;
  overflow: auto;
  background: #fff;
  z-index: 500;
  -webkit-transform: translate(100%, 0%) matrix(1, 0, 0, 1, 0, 0);
  transform: translate(100%, 0%) matrix(1, 0, 0, 1, 0, 0);
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.header__sidebar.active {
  -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
  transform: matrix(1, 0, 0, 1, 0, 0);
}

.header__sidebar__container {
  padding-top: 91px;
  padding-right: 30px;
  padding-bottom: 140px;
  padding-left: 96px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.header__sidebar__in {
  position: relative;
  z-index: 4;
  text-align: left;
}

.header__sidebar__before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 408px;
  height: 481px;
}

.header__sidebar__ov {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  visibility: hidden;
  z-index: 400;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  pointer-events: none;
}

.header__sidebar__ov.active {
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  opacity: 0.6;
  visibility: visible;
  pointer-events: auto;
}

.header__sidebar__country {
  padding-top: 68px;
}

.header__sidebar__country .header__country__g {
  display: inline-block;
  border-radius: 8.2px;
  -webkit-box-shadow: 0 8.8px 10px 0 rgba(186, 195, 203, 0.55);
  box-shadow: 0 8.8px 10px 0 rgba(186, 195, 203, 0.55);
}

.header__sidebar__country
  .header__personalized__inside
  .header__personalized__content
  > span {
  width: 174px;
  height: 50px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px 40px 10px 24px;
}

.header__sidebar__country
  .header__personalized__inside
  .header__personalized__content
  > span:before {
  right: 29px;
}

.header__sidebar__country
  .header__personalized__inside
  .header__personalized__content
  > span
  > p {
  font-size: 18px;
}

.header__sidebar__country .header__personalized__ae > ul > li > span {
  height: 50px;
  padding: 10px 24px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.header__sidebar__country .header__personalized__ae > ul > li > span > p {
  font-size: 18px;
}

.header__sidebar__logo {
  max-width: 209px;
  width: 100%;
  display: block;
  margin-left: 0;
  margin-right: auto;
  font-size: 0;
}

.header__sidebar__logo img {
  max-width: 100%;
  height: auto;
  display: block;
}

.header__sidebar__nav1 {
  padding-top: 57px;
  margin: 0;
}

.header__sidebar__nav1 > ul > li {
  margin-bottom: 15px;
}

.header__sidebar__nav1 > ul > li:last-child {
  margin-bottom: 0;
}

.header__sidebar__nav1 > ul > li > a {
  font-size: 25px;
  font-family: 'ProximaNova-Thin', sans-serif;
  color: #323e48;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.header__sidebar__nav1 > ul > li > a:hover {
  color: #4cce92;
}

.header__sidebar__nav2 {
  margin: 0;
  padding-top: 80px;
}

.header__sidebar__nav2 > ul > li {
  margin-bottom: 15px;
}

.header__sidebar__nav2 > ul > li:last-child {
  margin-bottom: 0;
}

.header__sidebar__nav2 > ul > li > a {
  font-size: 25px;
  font-family: 'ProximaNova-Thin', sans-serif;
  color: #797f80;
}

.header__sidebar__mail {
  padding-top: 93px;
}

.header__sidebar__mail span {
  font-family: 'ProximaNova-Semibold', sans-serif;
  color: #4cce92;
  font-size: 20px;
  display: block;
  margin-bottom: 7px;
}

.header__sidebar__mail a {
  font-family: 'ProximaNova-Thin', sans-serif;
  color: #797f80;
  font-size: 25px;
}

.header__sidebar__resp {
  display: none;
}

@media screen and (max-width: 1100px) {
  .header-contact .header__nav {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .header-home .header__wrap {
    height: 65px;
  }
  .header-home .header__position {
    height: 65px;
  }
  .header-home .header__countries {
    display: none;
  }
  .header-contact .header__wrap {
    height: 65px;
  }
  .header-contact .header__position {
    height: 65px;
  }
  .header__wrap.header--scrolling .header__position {
    height: 65px;
  }
  .header__logo {
    max-width: 188px;
  }
  .header__burguer__wrap {
    height: 65px;
  }
  .header-coruier .header__wrap.header--scrolling .header__position {
    height: 65px;
  }
  .header-coruier .header__wrap .header__position {
    height: 65px;
  }
}

@media screen and (max-width: 768px) {
  .header__burguer__container {
    padding: 0 36px 0 0;
  }
  .header__container {
    padding: 0 0 0 41px;
  }
  .header__details {
    padding-right: 89px;
  }
  .header-contact .header__countries {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .header__sidebar {
    width: 360px;
  }
  .header__sidebar__container {
    padding-left: 59px;
    padding-top: 75px;
  }
  .header__sidebar__nav1 > ul > li > a {
    font-size: 25px;
  }
  .header__sidebar__nav2 > ul > li > a {
    font-size: 20px;
  }
  .header__sidebar__logo {
    max-width: 170px;
  }
  .header__sidebar__country {
    padding-top: 45px;
  }
  .header-home .header__wrap {
    height: auto;
  }
  .header-home .header__position {
    height: 55px;
    background: transparent;
    border: none;
  }
  .header-home .header__nav {
    display: none;
  }
  .header-home .header__logo .logo-white {
    opacity: 1;
  }
  .header-home .header__logo .logo-color {
    opacity: 0;
  }
  .header-home .header__burger {
    background: #48c78c;
  }
  .header-home .header__burger .header__burger__in span:nth-child(1) {
    border-bottom: 2px #fff solid;
  }
  .header-home .header__burger .header__burger__in span:nth-child(2) {
    border-bottom: 2px #fff solid;
  }
  .header-home .header__burger.burger--scrolling {
    background: #fff;
  }
  .header-home .header__wrap.header--scrolling .header__position {
    border-bottom: solid 1px #f2f2f2;
  }
  .header__container {
    padding-left: 23px;
  }
  .header__burguer__container {
    padding-right: 19px;
  }
  .header-contact .header__wrap {
    height: 55px;
  }
  .header-contact .header__position {
    height: 55px;
  }
  .header-coruier .header__wrap.header--scrolling .header__position {
    height: 55px;
  }
  .header-coruier .header__wrap .header__position {
    height: 55px;
  }
  .header__burguer__wrap {
    height: 55px;
  }
  .header__countries {
    display: none;
  }
  .header__btn {
    display: none;
  }
  .header__logo {
    max-width: 130px;
  }
  .header__burguer__container {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .header__burger {
    width: 33px;
    height: 33px;
    background: rgba(76, 206, 146, 0.18);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 4px;
  }
  .header__burger .header__burger__in {
    width: 17px;
  }
  .header__burger .header__burger__in span:nth-child(1) {
    width: 12px;
  }
  .header__burger .header__burger__in.open span:nth-child(2) {
    -webkit-transform: translateY(-4px) translateX(-4px) rotate(45deg);
    transform: translateY(-4px) translateX(-4px) rotate(45deg);
  }
  .header__burger .header__burger__in.open span:nth-child(1) {
    -webkit-transform: translateY(4px) translateX(-4px) rotate(-45deg);
    transform: translateY(4px) translateX(-4px) rotate(-45deg);
  }
  .header__wrap.header--scrolling .header__position {
    height: 55px;
  }
  .header__sidebar__country
    .header__personalized__inside
    .header__personalized__content
    > span {
    height: 35px;
    width: 132px;
    padding: 10px 30px 10px 18px;
  }
  .header__sidebar__country
    .header__personalized__inside
    .header__personalized__content
    > span
    > p {
    font-size: 16px;
  }
  .header__sidebar__country
    .header__personalized__inside
    .header__personalized__content
    > span:before {
    right: 20px;
  }
  .header__sidebar__country .header__personalized__ae > ul > li > span {
    height: 35px;
    padding: 10px 18px;
  }
  .header__sidebar__country .header__personalized__ae > ul > li > span > p {
    font-size: 16px;
  }
  .header__sidebar__before {
    display: none;
  }
  .header__sidebar__nav1 {
    padding-top: 48px;
  }
  .header__sidebar__nav2 {
    padding-top: 50px;
  }
}

@media screen and (max-width: 400px) {
  .header__sidebar {
    width: 100%;
  }
  .header__sidebar__resp {
    position: absolute;
    bottom: -131px;
    left: 60%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 566px;
    height: 306px;
    display: block;
  }
  .header__sidebar__mail {
    padding-top: 141px;
    position: relative;
    z-index: 1;
  }
  .header__sidebar__nav1 {
    padding-top: 48px;
  }
  .header__sidebar__nav2 {
    padding-top: 50px;
  }
  .header__sidebar {
    height: 100%;
  }
  .header__sidebar__container {
    padding-bottom: 41px;
    padding-left: 52px;
    overflow: hidden;
  }
  .header__sidebar__mail span {
    color: #fff;
    margin-bottom: 8px;
  }
  .header__sidebar__mail a {
    color: #fff;
    font-size: 22px;
  }
}

.footer {
  position: relative;
  height: 418px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  overflow: hidden;
}

.footer__bg__desktop {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.footer__bg__tablet {
  display: none;
}

.footer__copyight__in {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  text-align: center;
}

.footer__copyight__terms__libro {
  position: absolute;
  width: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0;
}

.footer__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: left;
}

.footer__logo {
  max-width: 188px;
  width: 100%;
  margin-left: 0;
  margin-right: auto;
  display: block;
  font-size: 0;
  margin: 0;
  padding-top: 23px;
}

.footer__logo img {
  display: block;
  max-width: 100%;
  height: auto;
  display: block;
}

.footer__links > ul > li {
  margin-bottom: 10px;
}

.footer__links > ul > li > a {
  color: #fff;
  font-size: 15px;
  font-family: 'ProximaNova-Thin', sans-serif;
}

.footer__mail > span {
  font-family: 'ProximaNova-Semibold', sans-serif;
  color: #fff;
  font-size: 15px;
  display: block;
  margin-bottom: 12px;
}

.footer__mail > a {
  color: #fff;
  font-size: 15px;
  font-family: 'ProximaNova-Thin', sans-serif;
}

.footer__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.footer__social > span {
  font-family: 'ProximaNova-Semibold', sans-serif;
  color: #fff;
  font-size: 15px;
  display: block;
  margin: 0;
  padding-right: 18px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.footer__social > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer__social > ul > li {
  margin-right: 15px;
  margin-bottom: 0;
}

.footer__social > ul > li:lastchild {
  margin-right: 0;
}

.footer__social > ul > li > a {
  font-size: 23px;
  color: #fff;
}

.footer__social > ul > li > a:before {
  color: #fff;
}

.footer__social__country {
  width: 240px;
}

.footer__country {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 19px;
}

.footer__country article {
  background: #fff;
  display: inline-block;
  padding: 10px 17px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 14px;
  font-size: 0;
  border-radius: 5.7px;
  border: solid 0.6px #d6d9dc;
  margin: 0;
  margin-bottom: 14px;
}

.footer__country article:last-child {
  margin-bottom: 0;
}

.footer__country article > figure {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
}

.footer__country article > figure img {
  max-width: 100%;
  height: auto;
  display: block;
}

.footer__country article > figcaption {
  width: calc(100% - 18px);
  display: inline-block;
  vertical-align: middle;
  padding-left: 9px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.footer__country article > figcaption > p {
  font-size: 13px;
  color: #797f80;
  font-family: 'ProximaNova-Thin', sans-serif;
  margin: 0;
}

.footer__country article > figcaption > p > strong {
  font-family: 'ProximaNova-Semibold', sans-serif;
}

.footer__copyight {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 25px;
}

.footer__copyight__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer__copyight__left {
  padding-right: 10px;
}

.footer__copyight__left p {
  margin: 0;
  font-size: 13px;
  font-family: 'ProximaNova-Light', sans-serif;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
}

.footer__copyight__left img {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.footer__copyight__right {
  padding-left: 10px;
}

.footer__copyight__right p {
  margin: 0;
  font-size: 13px;
  font-family: 'ProximaNova-Light', sans-serif;
  color: #fff;
}

.footer__copyight__right a {
  color: #fff;
  font-family: 'ProximaNova-Bold', sans-serif;
}

.footer__copyight__center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  font-family: 'ProximaNova-Light', sans-serif;
  font-size: 13px;
  order: 2;
}

.footer__bottom {
  padding-bottom: 39px;
  text-align: center;
}

.footer__playstore span {
  display: block;
  font-family: 'ProximaNova-Semibold', sans-serif;
  font-size: 13px;
  color: #fff;
  display: block;
  margin-bottom: 13px;
}

.footer__playstore section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.footer__playstore section a {
  display: inline-block;
  margin: 4px;
  max-width: 107px;
  width: 100%;
}

.footer__playstore section a img {
  max-width: 100%;
  height: auto;
  display: block;
}

.footer__list__all > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
}

.footer__list__all > li > a {
  font-size: 13px;
  font-family: 'ProximaNova-Bold', sans-serif;
  color: #fff;
  margin: 0;
}

.footer__social__resp {
  display: none;
}

.footer__heart--resp {
  display: none !important;
}

@media screen and (max-width: 1024px) {
  .footer__social__country {
    position: relative;
    top: -6px;
  }
  .footer__logo {
    padding-top: 0;
  }
}

@media screen and (max-width: 1020px) {
  .footer__links {
    display: none;
  }
  .footer__social__country {
    top: 0;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    height: 328px;
  }
  .footer__top {
    padding: 0 20px 0 80px;
  }
  .footer__bg__tablet {
    display: block;
    position: absolute;
    height: 328px;
    width: 883px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 0;
  }
  .footer__bg__desktop {
    display: none;
  }
  .footer__logo {
    display: none;
  }
  .footer__social {
    display: none;
  }
  .footer__mail {
    padding-top: 15px;
  }
  .footer__mail > span {
    margin-bottom: 7px;
  }
  .footer__playstore span {
    margin-bottom: 11px;
  }
  .footer__social__resp {
    display: block;
    padding-top: 15px;
  }
  .footer__social__resp.footer__social {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .footer__social__resp.footer__social > span {
    padding-right: 0;
    font-size: 15px;
    margin-bottom: 9px;
  }
  .footer__social__resp.footer__social > ul {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .footer__social__resp.footer__social > ul > li:last-child {
    margin-right: 0;
  }
  .footer__country {
    margin-top: 0;
  }
  .footer__copyight__terms__libro {
    position: static;
    -webkit-transform: inherit;
    transform: inherit;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 13px;
    color: #fff;
    margin-top: 5px;
  }
  .footer__copyight__terms__libro .footer__list__all {
    margin: 0 5px;
  }
  .footer__bottom {
    padding-bottom: 24px;
  }
  .footer__top {
    padding-bottom: 25px;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    height: 350px;
  }
  .footer__bg__tablet {
    height: 350px;
  }
  .footer__logo {
    display: none;
  }
  .footer__mail {
    display: none;
  }
  .footer__country {
    display: none;
  }
  .footer__copyight {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 0;
  }
  .footer__container {
    padding: 0;
  }
  .footer__social__country {
    width: 100%;
    margin-bottom: 30px;
    display: none;
  }
  .footer__copyight__left {
    padding-right: 0;
    width: 100%;
  }
  .footer__copyight__right {
    width: 100%;
  }
  .footer__copyight__left img {
    margin-left: 5px;
    display: none;
  }
  .footer__heart--resp {
    display: inline-block !important;
    margin: 0 3px !important;
  }
  .footer__copyight__right {
    padding-left: 5px;
    margin-top: 5px;
  }
  .footer__social {
    display: block;
    text-align: center;
  }
  .footer__social > span {
    margin-bottom: 8px;
  }
  .footer__social > ul {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .footer__copyight__container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    padding: 0;
  }
  .footer__copyight__center {
    font-size: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 7px 0;
  }
  .footer__top {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    padding-bottom: 35px;
  }
  .footer__bottom {
    padding-bottom: 25px;
  }
  .footer__social__resp {
    padding-top: 0;
  }
  .footer__copyight__terms__libro {
    margin-top: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 0;
  }
  .footer__copyight__terms__libro .footer__list__all {
    width: 100%;
    margin: 0;
    margin-top: 5px;
  }
  .footer__copyight__left p {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .footer__list__all > li {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .footer__playstore span {
    margin-bottom: 16px;
  }
}

.button__g {
  display: inline-block;
  position: relative;
  border-radius: 0;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  cursor: pointer;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}

.button__g span,
.button__g input {
  border-radius: 0;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  z-index: 3;
  display: block;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.button__g--uppercase span,
.button__g--uppercase input {
  text-transform: uppercase;
}

.button__g--green {
  background: #4cce92;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 4px 0 #d6dadd;
  box-shadow: 0 2px 4px 0 #d6dadd;
}

.button__g--green span,
.button__g--green input {
  letter-spacing: 0.04px;
  font-family: 'ProximaNova-Semibold', sans-serif;
  color: #fff;
  font-size: 14px !important;
}

.button__g--green2 {
  border: 1px solid #4cce92;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 4px 0 #d6dadd;
  box-shadow: 0 2px 4px 0 #d6dadd;
}

.button__g--green2 span,
.button__g--green2 input {
  letter-spacing: 0.04px;
  font-family: 'ProximaNova-Semibold', sans-serif;
  color: #4cce92;
  font-size: 14px;
}

.button__g--morado {
  border: 1px solid #655db3;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 4px 0 #d6dadd;
  box-shadow: 0 2px 4px 0 #d6dadd;
}

.button__g--morado span,
.button__g--morado input {
  letter-spacing: 0.04px;
  font-family: 'ProximaNova-Semibold', sans-serif;
  color: #655db3;
  font-size: 14px;
}

.button__g--morado2 {
  border: 1px solid #655db3;
  background: #655db3;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 4px 0 #d6dadd;
  box-shadow: 0 2px 4px 0 #d6dadd;
}

.button__g--morado2 span,
.button__g--morado2 input {
  letter-spacing: 0.04px;
  font-family: 'ProximaNova-Semibold', sans-serif;
  color: #fff;
  font-size: 14px;
}

@media screen and (min-width: 1025px) {
  .button__g--green:hover {
    background: #4cc589;
  }
  .button__g--green2:hover {
    background: #4cc589;
  }
  .button__g--green2:hover span,
  .button__g--green2:hover input {
    color: #fff;
  }
  .button__g--morado:hover {
    background: #655db3;
  }
  .button__g--morado:hover span,
  .button__g--morado:hover input {
    color: #fff;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .button__g span,
  .button__g input {
    font-size: 13px !important;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .button__g span,
  .button__g input {
    font-size: 13px !important;
    line-height: 40px;
    height: 40px;
  }
}

.input__g {
  text-align: left;
  position: relative;
}

.input__g .input__g__input {
  width: 100%;
  height: 45px;
  line-height: 45px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
  margin: 0;
  padding: 0;
  border-radius: 0;
  background: none;
  background: #fff;
  border-radius: 3px;
  border: solid 1px #aab9c1;
  color: #797f80;
  font-family: 'ProximaNova-Thin', sans-serif;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  box-sizing: border-box;
}

.input__g .input__g__input::-webkit-input-placeholder {
  color: #797f80;
  font-family: 'ProximaNova-Thin', sans-serif;
  font-size: 14px;
}

.input__g .input__g__input::-moz-placeholder {
  color: #797f80;
  font-family: 'ProximaNova-Thin', sans-serif;
  font-size: 14px;
}

.input__g .input__g__input:-ms-input-placeholder {
  color: #797f80;
  font-family: 'ProximaNova-Thin', sans-serif;
  font-size: 14px;
}

.input__g .input__g__input::-moz-placeholder {
  color: #797f80;
  font-family: 'ProximaNova-Thin', sans-serif;
  font-size: 14px;
}

.input__g--textarea .input__g__input {
  height: 83px;
  line-height: 1.1;
  padding-top: 13px;
}

.input__g--select--default {
  position: relative;
}

.input__g--select--default select {
  position: relative;
}

.input__g--select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.input__g--select select::-ms-expand {
  display: none;
}

.input__g--select .input__g__ico {
  font-size: 5.5px;
  right: 15px;
  display: block;
  color: #aab9c1;
}

.input__g--select .input__g__ico:before {
  color: #aab9c1;
}

.input__g--inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.input__g--inline .input__g__title {
  margin: 0;
  margin-right: 15px;
}

.input__g--inline .input__g__inside {
  width: 100%;
}

.input__g__title {
  margin: 0;
  display: block;
  color: #797f80;
  font-family: 'ProximaNova-Thin', sans-serif;
  cursor: auto;
  font-size: 17px;
  z-index: 3;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.input__g--effect.active .input__g__input {
  border: 1px solid #4cce92;
}

.input__g--effect.active .select__personalized .select__personalized__inside {
  border: 1px solid #4cce92;
}

.input__g--effect.active2 .input__g__input {
  border: 1px solid #eefaf4;
  background: #eefaf4;
}

.input__g__inside {
  position: relative;
}

.input__g__ico {
  display: block;
  position: absolute;
  top: 0;
  right: 21px;
  font-family: 'ProximaNova-Light', sans-serif;
  color: #4cce92;
  font-size: 13px;
  line-height: 45px;
  pointer-events: none;
  z-index: 2;
}

.input__g__ico__left {
  left: 15px;
}

.input__g__ico__right {
  right: 15px;
}

.input__g__icon--left .input__g__input {
  padding-left: 45px;
}

.input__g__icon--right .input__g__input {
  padding-right: 45px;
}

.input__g__icon--all .input__g__input {
  padding-right: 45px;
  padding-left: 45px;
}

.input__g__textabs {
  display: block;
  position: absolute;
  top: 0;
  right: 21px;
  font-family: 'ProximaNova-Thin', sans-serif;
  color: #bac3cb;
  font-size: 14px;
  line-height: 45px;
  pointer-events: none;
  z-index: 2;
}

.input__g__textabs__left {
  left: 15px;
}

.input__g__textabs__right {
  right: 15px;
}

.input__g__textabs--left .input__g__input {
  padding-left: 45px;
}

.input__g__textabs--right .input__g__input {
  padding-right: 45px;
}

.input__g__textabs--all .input__g__input {
  padding-right: 45px;
  padding-left: 45px;
}

.input__g__button--right .input__g__button__right {
  position: absolute;
  top: 0;
  right: 0;
}

.input__g__button--right .input__g__input {
  padding-right: 40px;
}

.input__g--button .button__g {
  position: absolute;
  top: 0;
}

.input__g--button--right .input__g__input {
  padding-right: 60px;
}

.input__g--button--right .button__g {
  right: 0;
}

.input__g--button--left .input__g__input {
  padding-left: 60px;
}

.input__g--button--left .button__g {
  left: 0;
}

.input__g__button {
  line-height: 45px;
  border: none;
  background: none;
  padding: 0;
  padding: 0 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.input__g--checkbox label {
  padding-left: 16px;
  display: block;
}

.input__g--checkbox label > input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.input__g--checkbox label > span {
  position: relative;
  padding-left: 20px;
  font-size: 13px;
  color: #797f80;
  font-family: 'ProximaNova-Thin', sans-serif;
  letter-spacing: 0.93px;
  font-weight: 300;
  line-height: 1.2;
  display: block;
}

.input__g--checkbox label > span:after {
  content: '\e9d6';
  font-family: 'icomoon';
  position: absolute;
  left: -14px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
  font-size: 9px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
}

.input__g--checkbox label > span:before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #aab9c1;
  background: #fff;
  left: -16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
}

.input__g--checkbox label > span > a {
  font-family: 'ProximaNova-Thin', sans-serif;
  color: #4cce92;
  font-size: 13px;
  text-decoration: underline;
}

.input__g--checkbox label > input[type='checkbox']:checked + span:after {
  opacity: 1;
  visibility: visible;
}

.input__g--checkbox label > input[type='checkbox']:checked + span:before {
  background: #4cce92;
  border: 1px solid #4cce92;
}

.input__g--check--radio input[type='checkbox'],
.input__g--check--radio input[type='radio'] {
  display: none;
}

.input__g--check--radio--white .input__g__text {
  color: #fff;
  font-family: 'ProximaNova-Light', sans-serif;
  font-size: 13px;
}

.input__g--check--radio--white .input__g__text a {
  color: #fff;
  font-size: 13px;
}

.input__g__inside__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.input__g__figure {
  position: relative;
}

.input__g__figure:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
}

.input__g__figure:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
}

.input__g__text {
  padding-left: 12px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #d6dadd;
  letter-spacing: 0.1px;
  font-size: 16px;
  font-family: 'ProximaNova-Light', sans-serif;
}

.input__g__text a {
  padding-left: 3px;
  font-size: 16px;
  color: #d6dadd;
  text-decoration: underline;
}

.input__g--radio .input__g__figure {
  width: 19px;
  height: 19px;
}

.input__g--radio .input__g__figure:before {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 1px solid #d6dadd;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.input__g--radio .input__g__figure:after {
  right: 0;
  bottom: 0;
  margin: auto;
  width: 50%;
  height: 50%;
  border-radius: 100%;
  background: #4cce92;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.input__g--check--radio
  input[type='radio']:checked
  + .input__g--check--radio--label
  .input__g__figure:after {
  opacity: 1;
  visibility: visible;
}

.input__g--checkbox .input__g__figure {
  width: 15px;
  height: 15px;
}

.input__g--checkbox .input__g__figure:before {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  border: 1px solid #635daf;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.input__g--checkbox .input__g__figure:after {
  content: '\ea10';
  font-family: 'icomoon';
  top: 3px;
  right: 0;
  text-align: center;
  margin: auto;
  opacity: 0;
  visibility: hidden;
  font-size: 10px;
  color: #fff;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.input__g--check--radio
  input[type='checkbox']:checked
  + .input__g--check--radio--label
  .input__g__figure:after {
  opacity: 1;
  visibility: visible;
}

.input__g--check--radio
  input[type='checkbox']:checked
  + .input__g--check--radio--label
  .input__g__figure:before {
  background: #635daf;
}

.input__g__radio__button {
  height: 45px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid silver;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  overflow: hidden;
}

.input__g__radio__button:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  width: 0;
  background: silver;
  -webkit-transition: 0s all;
  transition: 0s all;
}

.input__g__radio__button:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: silver;
  height: 100%;
  width: 100%;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  width: 100%;
  opacity: 0;
  -webkit-transition: 0.4s transform, 0s opacity 0.4s;
  transition: 0.4s transform, 0s opacity 0.4s;
}

.input__g__radio__text {
  font-size: 12px;
  display: block;
  margin-top: 12px;
  color: silver;
  text-align: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.input__g__radio__icon {
  font-size: 40px;
  color: silver;
  display: block;
  margin: 0;
  position: relative;
  z-index: 3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.input__g__radio__icon:before {
  color: silver;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.input__g__file--white .input__g__file__label {
  border: 2px dashed #bac3cb;
  background: transparent;
}

.input__g__file--white .input__g__file__label .input__g__file__ico {
  color: #fff;
}

.input__g__file--white .input__g__file__label .input__g__file__ico:before {
  color: #fff;
}

.input__g__file--white .input__g__file__label .input__g__file__text {
  color: #fff;
}

.input__g__file {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.input__g__file input[type='file'] {
  display: none;
}

.input__g__file__label {
  display: block;
  position: relative;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
  border-radius: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 0;
  border: 2px dashed #bac3cb;
  padding: 29px 20px;
  height: 165px;
  overflow: hidden;
  text-align: center;
}

.input__g__file__text {
  display: block;
  margin: 0;
  color: #d6dadd;
  font-family: 'ProximaNova-Light', sans-serif;
  max-width: 440px;
  width: 100%;
  margin: auto;
  line-height: 1.55;
  font-family: 'ProximaNova-Light', sans-serif;
  font-size: 13px;
}

.input__g__file__ico {
  display: block;
  margin: 0 auto;
  pointer-events: none;
  z-index: 2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 15px;
  font-size: 27px;
}

.form__block__pie {
  font-size: 13px;
  font-family: 'ProximaNova-Light', sans-serif;
  line-height: 1.2;
  color: #4cce92;
}

.form__block__pie p {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.form__block__pie i {
  color: #edeffa;
  font-style: normal;
  padding: 0 2px;
}

.form__block__check {
  margin-top: 13px;
  position: relative;
}

.form__block__check .formError {
  width: 100%;
  height: 100%;
  border: 1px solid #e6465a;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 !important;
  font-size: 0;
}

.form__block__button {
  margin-top: 43px;
}

.input__g__lines {
  padding: 0;
  margin: 0;
}

.input__g__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.input__g__line .input__g {
  width: 100%;
  margin-bottom: 30px;
}

.input__g--two-columns .input__g {
  width: 49%;
  display: inline-block;
  margin-right: 2%;
}

.input__g--two-columns .input__g:last-child {
  margin-right: 0;
}

.input__g--three-columns .input__g {
  width: 32%;
  display: inline-block;
  margin-right: 2%;
}

.input__g--three-columns .input__g:last-child {
  margin-right: 0;
}

.input__g--four-columns .input__g {
  width: 23.5%;
  display: inline-block;
  margin-right: 2%;
}

.input__g--four-columns .input__g:last-child {
  margin-right: 0;
}

@media screen and (max-width: 960px) {
  .input__g--four-columns .input__g {
    width: 49%;
    display: inline-block;
    margin-right: 2%;
  }
  .input__g--four-columns .input__g:nth-child(2n + 2) {
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .input__g--four-columns .input__g {
    width: 100%;
    display: inline-block;
    margin-right: 0;
  }
  .input__g--two-columns .input__g {
    width: 100%;
    display: inline-block;
    margin-right: 0;
  }
  .input__g--three-columns .input__g {
    width: 100%;
    display: inline-block;
    margin-right: 0;
  }
  .input__g__file__label {
    height: auto !important;
  }
}

.input__g--select--sumoselect .SumoSelect {
  width: 100%;
}

.input__g--select--sumoselect .SelectBox {
  padding: 0 65px 0 30px !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.input__g--select--sumoselect .SumoSelect > .CaptionCont > label {
  display: none;
}

.input__g--select--sumoselect .SumoSelect > .CaptionCont {
  height: 65px;
  line-height: 65px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid #edeffa;
  color: #d6dadd;
  font-size: 14px;
  cursor: pointer;
}

.input__g--select--sumoselect .SumoSelect > .CaptionCont > span {
  padding-right: 0;
}

.input__g--select--sumoselect .SumoSelect > .optWrapper {
  top: 58px !important;
}

.input__g--select--sumoselect .SumoSelect.open > .CaptionCont,
.input__g--select--sumoselect .SumoSelect:focus > .CaptionCont,
.input__g--select--sumoselect .SumoSelect:hover > .CaptionCont {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #edeffa;
}

.input__g--select--sumoselect .SumoSelect > .optWrapper > .options li.opt {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 16.5px 30px;
  font-size: 14px;
  font-family: 'ProximaNova-Light', sans-serif;
  color: #d6dadd;
  border-bottom: 1px solid #edeffa;
}

.input__g--select--sumoselect
  .SumoSelect
  > .optWrapper
  > .options
  li.opt.selected {
  background-color: rgba(27, 27, 27, 0.09);
}

.input__g--select--sumoselect
  .SumoSelect
  > .optWrapper
  > .options
  li.opt:hover {
  background-color: rgba(27, 27, 27, 0.09);
}

.input__g--select--sumoselect .SumoSelect.open > .optWrapper {
  -webkit-box-shadow: 0 9px 11px 0 rgba(27, 27, 27, 0.17);
  box-shadow: 0 9px 11px 0 rgba(27, 27, 27, 0.17);
}

.input__g--select--sumoselect .SumoSelect > .CaptionCont {
  background-color: rgba(255, 255, 255, 0.15);
  border: none;
  color: #fff;
}

.input__g--select--sumoselect .SumoSelect.open > .CaptionCont,
.input__g--select--sumoselect .SumoSelect:focus > .CaptionCont,
.input__g--select--sumoselect .SumoSelect:hover > .CaptionCont {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none;
}

.select--animate--one .SumoSelect > .optWrapper {
  display: block !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow: hidden;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: center top;
  transform-origin: center top;
  -webkit-transition: -webkit-transform 300ms ease;
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
}

.select--animate--one .SumoSelect.open > .optWrapper {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.input__g--select--select2 {
  width: 100%;
}

.input__g--select--select2 .select2-container {
  width: 100% !important;
}

.input__g--select--select2
  .select2-container--default
  .select2-selection--single {
  height: 65px;
  line-height: 65px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #000;
  color: #c0c0c0;
  font-size: 16px;
  cursor: pointer;
}

.input__g--select--select2
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 65px;
}

.input__g--select--select2 .select2-selection__arrow {
  display: none !important;
}

.input__g--select--chosen .chosen-container-single .chosen-single {
  height: 50px;
  line-height: 50px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #000;
  color: #c0c0c0;
  font-size: 16px;
  cursor: pointer;
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

.input__g--select--chosen .chosen-container-single .chosen-single div {
  display: none;
}

.select__personalized {
  width: 100%;
  position: relative;
}

.select__personalized .select__personalized__ae .select__personalized__menu {
  background: #fff;
  position: absolute;
  top: 0;
  width: 104px;
  z-index: 20;
  padding: 5.5px 0;
  margin: 0px;
  margin-top: -1px;
  border-radius: 5px;
  -webkit-box-shadow: 1px 4px 10px 0 #dcdcdc;
  box-shadow: 1px 4px 10px 0 #dcdcdc;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  list-style-type: none;
}

.select__personalized .select__personalized__inside {
  position: relative;
  border: 1px solid #aab9c1;
  height: 45px;
  line-height: 45px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 11px 16px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 3px;
}

.select__personalized .select__personalized__inside:before {
  content: '\e939';
  font-family: 'icomoon';
  display: block;
  font-size: 5.5px;
  right: 15px;
  display: block;
  color: #aab9c1;
  position: absolute;
  top: 0;
  right: 8px;
  line-height: 45px;
  z-index: 3;
  pointer-events: none;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.select__personalized__menu li {
  padding: 5.5px 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.select__personalized__menu li:hover {
  background: #fff;
}

.select__personalized__menu li.selected {
  background: #fff;
}

.select__personalized__menu li span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  height: 100%;
}

.select__personalized__menu li span img {
  margin: 0;
  width: 16px;
  max-width: 100%;
  overflow: hidden;
}

.select__personalized__menu li span p {
  margin: 0;
  font-size: 11px;
  font-family: 'ProximaNova-Light', sans-serif;
  color: #797f80;
  line-height: 1.27;
  letter-spacing: 0.22px;
  padding-left: 7px;
}

.select__personalized__content span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  height: 100%;
}

.select__personalized__content span img {
  margin: 0;
  width: 16px;
  max-width: 100%;
  overflow: hidden;
}

.select__personalized__content span p {
  margin: 0;
  font-size: 0;
  font-family: 'ProximaNova-Light', sans-serif;
  color: #797f80;
  line-height: 1.27;
  letter-spacing: 0.22px;
  padding-left: 0;
}

.select__personalized .ae-disabled {
  pointer-events: none;
}

.select__personalized--hide {
  display: none;
}

.input__g__block__on {
  position: absolute;
  top: 0;
  left: 0;
}

.select__personalized--hideall {
  display: none;
}

.input__g--country .select__personalized__inside {
  width: 61px;
}

.input__g--country.input__g .input__g__input {
  padding-left: 101px;
}

.input__g--country--only.input__g .input__g__input {
  padding-left: 80px !important;
}

.input__g--city .select__personalized__inside {
  width: 61px;
}

.input__g--city.input__g .input__g__input {
  padding-left: 74px;
}

.input__g--city .select__personalized__menu {
  width: 120px !important;
}

.input__g--city .select__personalized__menu li {
  padding: 5px 12px !important;
}

.input__g--city .select__personalized__menu li span img {
  width: 13px !important;
}

.input__g--city .select__personalized__menu li span p {
  font-size: 10px !important;
}

.input__g__code {
  position: absolute;
  top: 0;
  left: 61px;
  width: 40px;
  text-align: center;
  font-size: 13px;
  height: 45px;
  line-height: 45px;
  font-family: 'ProximaNova-Light', sans-serif;
  color: #797f80;
}

.input__g__inside {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.input__g__inside .formError {
  position: static !important;
  margin-top: 4px !important;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  font-size: 13px;
  line-height: 1;
  color: #e6465a;
  font-family: 'ProximaNova-Light', sans-serif;
}

.input__g__inside .formErrorArrow {
  position: absolute;
  top: 0;
  width: 100%;
  height: 45px;
  border: 1px solid #e6465a;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px;
}

.input__g--textarea .formErrorArrow {
  height: 83px !important;
}

.toogle__password--icon {
  pointer-events: auto !important;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.toogle__password--icon:before {
  color: #dfdfdf;
}

.toogle__password--icon.show:before {
  color: #797f80;
}

.title__g__title h1,
.title__g__title h2,
.title__g__title h3,
.title__g__title h4,
.title__g__title h5 {
  margin: 0;
  letter-spacing: normal;
  line-height: 1.2;
}

.title__g__title > p {
  margin: 0;
  letter-spacing: normal;
  line-height: 1.2;
}

.g--uppercase {
  text-transform: uppercase;
}

.tfz15 {
  font-size: 15px;
}

.tfz18 {
  font-size: 18px;
}

.tfz20 {
  font-size: 20px;
}

.tfz25 {
  font-size: 25px;
}

.tfz28 {
  font-size: 28px;
}

.tfz30 {
  font-size: 30px;
}

.tfz35 {
  font-size: 35px;
}

.tfz40 {
  font-size: 40px;
}

.tfz45 {
  font-size: 45px;
}

.tfz50 {
  font-size: 50px;
}

.fflight {
  font-family: 'ProximaNova-Light', sans-serif;
}

.ffthin {
  font-family: 'ProximaNova-Thin', sans-serif;
}

.ffregular {
  font-family: 'ProximaNova-Regular', sans-serif;
}

.tmorado {
  color: #635daf;
}

.tgreen {
  color: #4cce92;
}

.tsilver {
  color: #3e424a;
}

.tsilver3 {
  color: #797f80;
}

.tsilver2 {
  color: #797f80;
}

.tblack {
  color: #394049;
}

.tblack2 {
  color: #323e48;
}

.tsilver33 {
  color: #aeb4c3;
}

.twhite {
  color: #fff;
}

.twhite .title__g__title h1,
.twhite .title__g__title h2,
.twhite .title__g__title h3,
.twhite .title__g__title h4,
.twhite .title__g__title h5 {
  color: #fff;
}

.twhite .title__g__title > span {
  color: #fff;
}

.twhite.title__g__text {
  color: #fff;
}

.title__g__text {
  margin-top: 39px;
  line-height: 1.42;
  letter-spacing: normal;
}

.title__g__button {
  margin-top: 26px;
}

.title__g2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.title__g2 figure {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 13.1px;
  font-size: 0;
  -webkit-box-shadow: 0 9px 10px 0 rgba(215, 231, 245, 0.55);
  box-shadow: 0 9px 10px 0 rgba(215, 231, 245, 0.55);
  padding: 9px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.title__g2 figure img {
  max-width: 100%;
  height: auto;
  display: block;
}

.title__g2 .title__g__title__text {
  width: calc(100% - 80px);
  padding-left: 30px;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .title__g2 figure {
    width: 55px;
    height: 55px;
  }
  .title__g2 .title__g__title__text {
    width: calc(100% - 55px);
    padding-left: 16px;
  }
}

.title__g2green {
  background: #4cce92;
}

.title__g2morado {
  background: #635daf;
}

.menu__burger {
  display: block;
  width: 22px;
  height: 15px;
  position: relative;
  cursor: pointer;
}

.menu__burger span {
  display: block;
  width: 100%;
  height: 2px;
  background: #fff;
  -webkit-transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.menu__burger--line span:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
}

.menu__burger--line span:nth-child(2) {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -8px;
}

.menu__burger--line span:nth-child(3) {
  position: absolute;
  bottom: 0;
}

.menu__burger--line:hover span:nth-child(2) {
  right: 0;
}

.menu__burger--line.active span:nth-child(2) {
  right: 0;
}

.menu__burger--inline span:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
}

.menu__burger--inline span:nth-child(2) {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 80%;
}

.menu__burger--inline span:nth-child(3) {
  position: absolute;
  bottom: 0;
}

.menu__burger--inline:hover span:nth-child(2) {
  width: 100%;
}

.menu__burger--inline:hover span:nth-child(1) {
  width: 100%;
}

.menu__burger--line.active span:nth-child(2) {
  width: 100%;
}

.menu__burger--line.active span:nth-child(1) {
  width: 100%;
}

.menu__burger--close span:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
}

.menu__burger--close span:nth-child(2) {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.menu__burger--close span:nth-child(3) {
  position: absolute;
  bottom: 0;
}

.menu__burger--close.active span:nth-child(2) {
  opacity: 0;
}

.menu__burger--close.active span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 7px;
}

.menu__burger--close.active span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  bottom: 8px;
}

.menu__burger--more span:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
}

.menu__burger--more span:nth-child(2) {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.menu__burger--more span:nth-child(3) {
  position: absolute;
  bottom: 0;
}

.menu__burger--more:hover span:nth-child(1) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.menu__burger--more:hover span:nth-child(3) {
  bottom: 10px;
}

.menu__burger--more.active span:nth-child(1) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  top: 11px;
}

.menu__burger--more.active span:nth-child(3) {
  bottom: 10px;
}

.menu__burger--icon {
  border-radius: 5px;
  color: white;
  font-size: 25px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.menu__burguer__icon {
  line-height: 35px;
  display: block;
}

.menu__burger--center span:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 50%;
}

.menu__burger--center span:nth-child(2) {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.menu__burger--center span:nth-child(3) {
  position: absolute;
  bottom: 0;
  width: 50%;
  left: 0;
  right: 0;
}

.menu__burger--center:hover span:nth-child(1) {
  width: 100%;
}

.menu__burger--center:hover span:nth-child(3) {
  width: 100%;
}

.menu__burger--center.active span:nth-child(1) {
  width: 100%;
}

.menu__burger--center.active span:nth-child(3) {
  width: 100%;
}

.pop__up__wrap.active .pop__up {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.pop__up__wrap.active .pop__up__overlay {
  opacity: 0.5;
  visibility: visible;
  pointer-events: auto;
}

.pop__up {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 95%;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 1200;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.pop__up__scroll {
  overflow: auto;
  height: 100%;
  padding-right: 20px;
}

.pop__up__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  z-index: 1000;
}

.pop__up__close {
  position: absolute;
  top: 2px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  right: -30px;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.pop__up__close:before {
  color: #fff;
}

.pop__up__close:hover {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

body.pop__up__active {
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .pop__up__close {
    right: 15px;
    top: 15px;
    color: #000;
  }
  .pop__up__close:before {
    color: #000;
  }
  .pop__up {
    height: 90% !important;
    padding: 50px 15px 30px 30px !important;
  }
  .pop__up__scroll {
    padding-right: 15px !important;
  }
}

.pop__up--politicas .pop__up {
  max-width: 750px;
  width: 90%;
  height: 450px;
  padding: 52px 68px 66px 89px;
}

.pop__up--politicas .pop__up__title {
  max-width: 462px;
  width: 100%;
  margin: auto;
  margin-bottom: 40px;
  text-align: center;
}

.pop__up--politicas .pop__up__title h3 {
  line-height: 1.3;
}

.pop__up--politicas .content__styles__all {
  color: #4cce92;
  font-size: 14px;
}

.pop__up--terminos .pop__up {
  max-width: 750px;
  width: 90%;
  height: 450px;
  padding: 52px 68px 66px 89px;
}

.pop__up--terminos .pop__up__title {
  max-width: 462px;
  width: 100%;
  margin: auto;
  margin-bottom: 40px;
  text-align: center;
}

.pop__up--terminos .pop__up__title h3 {
  line-height: 1.3;
}

.pop__up--terminos .content__styles__all {
  color: #4cce92;
  font-size: 14px;
}

.pop__up--postor .pop__up {
  max-width: 700px;
  width: 90%;
  height: 620px;
  padding: 53px 54px 53px 53px;
}

.pop__up--postor .pop__up__title {
  max-width: 592px;
  width: 100%;
  margin: auto;
  margin-bottom: 35px;
  text-align: center;
}

.pop__up--postor .pop__up__title h3 {
  line-height: 1.3;
  font-size: 16px;
  color: #aab9c1;
  margin: 0;
  font-family: 'ProximaNova-Light', sans-serif;
}

.pop__up--postor .content__styles__all {
  color: #4cce92;
  font-size: 14px;
}

.g--uppercase {
  text-transform: uppercase;
}

.g__item__producto > figure {
  margin: 0;
  font-size: 0;
}

.g__item__producto > figure img {
  max-width: 100%;
  height: auto;
  display: block;
}

.g__item__producto > figcaption {
  padding: 22px 24px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
}

.g__item__producto > figcaption > span {
  font-size: 10px;
  letter-spacing: 1.79px;
  color: #4cce92;
  font-size: 10px;
  font-family: 'ProximaNova-Thin', sans-serif;
  text-transform: uppercase;
}

.g__item__producto > figcaption > h3 {
  font-size: 17px;
  line-height: 1.18;
  font-weight: 500;
  font-family: 'ProximaNova-Black', sans-serif;
  color: #797f80;
  margin: 0;
  margin-top: 10px;
}

.item__g__broker {
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
}

.item__g__broker > .top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.item__g__broker > .top > figcaption {
  width: calc(100% - 190px);
  padding: 33px 33px 33px 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.item__g__broker > .top > figcaption > .info > h3 {
  font-family: 'ProximaNova-Thin', sans-serif;
  color: #4cce92;
  font-size: 18px;
}

.item__g__broker > .top > figcaption > .info > span {
  font-size: 14px;
  color: #ecedf6;
}

.item__g__broker > .top > figcaption > .links {
  margin-top: 25px;
}

.item__g__broker > .top > figcaption > .links > a {
  display: block;
}

.item__g__broker > .top > figcaption > .links > .one {
  color: #797f80;
  font-family: 'ProximaNova-Regular', sans-serif;
  font-size: 15px;
}

.item__g__broker > .top > figcaption > .links > .one > span {
  margin-right: 7px;
}

.item__g__broker > .top > figcaption > .links > .one > span:before {
  color: #323e48;
}

.item__g__broker > .top > figcaption > .links > .two {
  margin-top: 10px;
  color: #323e48;
  font-size: 15px;
  font-family: 'ProximaNova-Regular', sans-serif;
}

.item__g__broker > .top > .figure {
  display: block;
  width: 190px;
  height: 190px;
  font-size: 0;
  margin: 0;
  position: relative;
}

.item__g__broker > .top > .figure img {
  max-width: 100%;
  height: auto;
  display: block;
}

.item__g__broker > .top > .figure > span {
  display: block;
  background-size: 100%;
  width: 28px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}

.item__g__broker > .bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 18px 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.item__g__broker > .bottom:before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
}

.item__g__broker > .bottom > section {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.item__g__broker > .bottom > section > p {
  font-family: 'ProximaNova-Thin', sans-serif;
  color: #ecedf6;
  font-size: 13px;
  margin: 0;
  text-transform: uppercase;
  padding-right: 15px;
}

.item__g__broker > .bottom > section > p > i {
  margin-right: 8px;
}

.item__g__broker > .bottom > section > p > i:before {
  color: #ecedf6;
}

.item__g__broker > .bottom > section > span {
  font-family: 'ProximaNova-Thin', sans-serif;
  color: #ecedf6;
  font-size: 13px;
  text-transform: uppercase;
  position: relative;
  padding-left: 18px;
  position: relative;
}

.item__g__broker > .bottom > section > span:before {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 18px;
  width: 1px;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}

.item__g__broker > .bottom > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.item__g__broker > .bottom > ul > li {
  margin-right: 17px;
}

.item__g__broker > .bottom > ul > li:last-child {
  margin-right: 0;
}

.item__g__broker > .bottom > ul > li > a:before {
  color: #ecedf6;
}

.item__g__broker > .bottom > ul > li > .icon-google {
  font-size: 22px;
}

@media screen and (max-width: 480px) {
  .item__g__broker > .top {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .item__g__broker > .top > figcaption {
    width: 100%;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    padding: 25px;
  }
  .item__g__broker > .top > .figure {
    height: auto;
    width: 100%;
  }
  .item__g__broker > .top > .figure img {
    width: 100%;
  }
  .item__g__broker > .bottom {
    padding: 10px 15px;
  }
  .item__g__broker > .bottom > section > p {
    font-size: 11px;
  }
  .item__g__broker > .bottom > section > span {
    font-size: 11px;
  }
  .item__g__broker > .bottom > ul > li {
    margin-right: 13px;
  }
  .item__g__broker > .bottom > ul > li > a {
    font-size: 14px;
  }
}

@media screen and (max-width: 360px) {
  .item__g__broker > .bottom {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .item__g__broker > .bottom > ul {
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 10px;
  }
}

.item__g__postor {
  text-align: center;
  background: #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 27px 25px 24px 25px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.item__g__postor > figure {
  width: 95px;
  height: 95px;
}

.item__g__postor > figure img {
  max-width: 100%;
  margin: auto;
  display: block;
}

.item__g__postor > section {
  font-family: 'ProximaNova-Light', sans-serif;
  font-size: 15px;
  line-height: 1.4;
  color: #4cce92;
  margin-top: 15px;
}

.item__g__postor > figcaption {
  margin-top: 13px;
}

.item__g__postor > figcaption > h3 {
  font-size: 17px;
  line-height: 1.29;
  color: #4cce92;
  margin-bottom: 5px;
}

.item__g__postor > figcaption > span {
  color: #323e48;
  font-size: 15px;
  font-family: 'ProximaNova-Regular', sans-serif;
  line-height: 1.4;
}

.item__g__postor > div {
  margin-top: 29px;
}

.item__g__postor > div > span {
  display: block;
  font-family: 'ProximaNova-Thin', sans-serif;
  color: #4cce92;
  font-size: 15px;
}

.item__g__postor > div > p {
  margin: 0;
  margin-top: 3px;
  font-family: 'ProximaNova-Light', sans-serif;
  color: #4cce92;
  font-size: 15px;
}

.item__g__postor > span {
  display: block;
  color: #aab9c1;
  font-family: 'ProximaNova-Light', sans-serif;
  margin-top: 23px;
  font-size: 14px;
}

.item__g__postor .button__g {
  width: 100%;
  text-align: center;
  margin-top: 17px;
}

.item__g__download {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 22px 28px 22px 28px;
  background: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: solid 1px #3e424a;
  border-radius: 5px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.item__g__download figure {
  margin: 0;
  width: 32px;
}

.item__g__download figure img {
  max-width: 100%;
  height: auto;
  display: block;
}

.item__g__download figcaption {
  margin: 0;
  padding-left: 27px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: calc(100% - 32px);
}

.item__g__download figcaption h3 {
  font-size: 15px;
  font-family: 'ProximaNova-Light', sans-serif;
  color: #797f80;
  line-height: 1.3;
  margin: 0;
}

.item__g__download figcaption strong {
  display: block;
  font-weight: normal;
}

.item__g__download:hover {
  border: solid 1px #4cc589;
}

.g__item__project {
  position: relative;
}

.g__item__project figure {
  margin: 0;
  height: 260px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.g__item__project figure:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 130px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0)),
    to(rgba(0, 0, 0, 0.5))
  );
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}

.g__item__project figcaption {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  padding: 13px 28px 13px 28px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.g__item__project figcaption h3 {
  color: #fff;
  font-size: 13px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  font-family: 'ProximaNova-Thin', sans-serif;
}

.g__links > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.g__links > ul > li {
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.g__links > ul > li > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  height: 100%;
  background: #394049;
  color: #fff;
  font-size: 13px;
  font-family: 'ProximaNova-Regular', sans-serif;
  padding: 0 25px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.g__links > ul > li > a:before {
  content: '';
  width: 30px;
  height: 30px;
  background-size: 100%;
  margin-right: 12px;
}

.g__links > ul > li > a:hover {
  background: #aab9c1;
}

.g__links > ul > li.current_page_item > a,
.g__links > ul > li.active > a {
  background: #323e48;
}

.g__social__list.on > ul {
  height: auto;
}

.g__social__list > ul {
  height: 150px;
  overflow: hidden;
}

.g__social__list > ul > li {
  margin: 0;
}

.g__social__list > ul > li > a {
  color: #fff;
  background: red;
  display: block;
  height: 50px;
  line-height: 50px;
  width: 50px;
  text-align: center;
  font-size: 20px;
}

.g__social__list > ul > li > a:before {
  color: #fff;
}

.g__social__list > span {
  display: none;
  height: 50px;
  width: 50px;
  cursor: pointer;
  background: #aab9c1;
  position: relative;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.g__social__list > span:hover {
  background: #eefaf4;
}

.g__social__list > span.on:before {
  top: 15px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.g__social__list > span:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin: auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 2px;
  height: 20px;
  background: #fff;
}

.g__social__list > span:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin: auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 20px;
  height: 2px;
  background: #fff;
}

.arrows__general--t1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.arrows__general--t1 span,
.arrows__general--t1 a,
.arrows__general--t1 div {
  display: block;
  height: 40px;
  line-height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 100%;
  font-size: 14px;
  color: #4cce92;
  margin: 0;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  cursor: pointer;
}

.arrows__general--t1 span:before,
.arrows__general--t1 a:before,
.arrows__general--t1 div:before {
  color: #4cce92;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  line-height: 40px;
}

.arrows__general--t1 span:hover,
.arrows__general--t1 a:hover,
.arrows__general--t1 div:hover {
  color: #323e48;
}

.arrows__general--t1 span:hover:before,
.arrows__general--t1 a:hover:before,
.arrows__general--t1 div:hover:before {
  color: #323e48;
}

.arrows__g__slider .slick-slider {
  padding: 0 28px;
}

.arrows__g__slider .slick-prev,
.arrows__g__slider .slick-next {
  position: absolute;
  border: none;
  padding: 0;
  top: 50%;
  margin-top: -32px;
  height: 40px;
  line-height: 40px;
  width: 24px;
  border-radius: 100%;
  text-align: center;
  pointer-events: auto;
  font-size: 0;
  background: none;
  z-index: 50;
  cursor: pointer;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.arrows__g__slider .slick-prev:hover:before,
.arrows__g__slider .slick-next:hover:before {
  color: #323e48;
}

.arrows__g__slider .slick-prev:before,
.arrows__g__slider .slick-next:before {
  font-family: 'icomoon';
  font-size: 14px;
  color: #4cce92;
  line-height: 40px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.arrows__g__slider .slick-prev {
  left: 0;
}

.arrows__g__slider .slick-prev:before {
  content: '\e916';
}

.arrows__g__slider .slick-next {
  right: 0;
}

.arrows__g__slider .slick-next:before {
  content: '\e915';
}

@media screen and (max-width: 767px) {
  .arrows__g__slider .slick-slider {
    padding: 0 !important;
  }
  .arrows__g__slider .slick-prev,
  .arrows__g__slider .slick-next {
    display: none !important;
  }
}

.dots__g__slider .slick-dots {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  pointer-events: none;
}

.dots__g__slider .slick-dots li {
  display: inline-block;
  vertical-align: top;
  margin: 0 6px;
  pointer-events: auto;
  width: auto;
}

.dots__g__slider .slick-dots li:hover button {
  opacity: 1;
}

.dots__g__slider .slick-dots li.slick-active button {
  opacity: 1;
}

.dots__g__slider .slick-dots button {
  margin: 0;
  padding: 0;
  border: none;
  font-size: 0;
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #635daf;
  opacity: 0.23;
  cursor: pointer;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.slick-dots li.slick-active button:before,
.slick-dots li button:before {
  color: transparent !important;
}

@media screen and (max-width: 767px) {
  .dots__g__slider .slick-slider {
    margin-bottom: 0;
  }
}

.pager__g__pages__arrows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pager__g__pages__arrows > a {
  margin: 0;
  width: 24px;
  height: 24px;
  line-height: 24px;
  color: #d6dadd;
  margin-right: 33px;
  text-align: center;
}

.pager__g__pages__arrows > .next {
  margin-right: 0;
  margin-left: 33px;
}

.pager__g__nav__pages {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pager__g__nav__pages .input__g--select {
  width: 78px;
}

.pager__g__nav__pages > span {
  font-size: 15px;
  color: #4cce92;
  margin-right: 20px;
}

.pager__g__nav__pages > p {
  margin: 0;
  font-size: 15px;
  color: #4cce92;
  margin-left: 16px;
}

@media screen and (max-width: 767px) {
  .pager__g__pages__arrows > .next {
    margin-left: 15px;
  }
  .pager__g__pages__arrows > .previous {
    margin-right: 15px;
  }
}

.filter__g {
  padding: 36px 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.filter__g ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.filter__g ul li {
  padding-right: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.filter__g ul .ubicacion__f {
  max-width: 300px;
  width: 100%;
}

.filter__g ul .rangeprecio__f {
  max-width: 400px;
  width: 100%;
}

.filter__g ul .rangeprecio__f .rangeprecio__wrap {
  position: relative;
}

.filter__g ul .rangeprecio__f .rangeprecio__wrap #slider-range {
  width: 100%;
}

.filter__g ul .rangeprecio__f .rangeprecio__wrap > span {
  width: 100%;
}

.filter__g ul .rangeprecio__f .rangeprecio__wrap > span > input {
  width: 100%;
}

.filter__g ul .habitaciones__f {
  max-width: 110px;
  width: 100%;
}

.filter__g ul .tipo__f {
  max-width: 270px;
  width: 100%;
}

.filter__g ul .btn__f {
  max-width: 150px;
  width: 100%;
  padding-right: 0;
}

@media screen and (max-width: 960px) {
  .filter__g ul .rangeprecio__f {
    max-width: 200px;
  }
  .filter__g ul .btn__f {
    max-width: 85px;
  }
  .filter__g ul .btn__f .button__g span,
  .filter__g ul .btn__f input {
    padding: 0 15px;
  }
}

@media screen and (max-width: 767px) {
  .filter__g ul {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .filter__g ul > li {
    width: 100% !important;
    max-width: 100% !important;
    padding-right: 0 !important;
    margin-bottom: 20px;
  }
  .filter__g ul > li:last-child {
    margin-bottom: 0;
  }
  .filter__g ul .btn__f .button__g {
    width: 100%;
  }
  .filter__g ul .btn__f .button__g input {
    width: 100%;
  }
}

.slider__clients__slider ul {
  margin: 0;
  padding: 0;
}

.slider__clients__slider li {
  list-style: none;
  margin: 0;
}

.slider__clients__list figure,
.slider__clients__list a {
  display: block;
  max-width: 210px;
  width: 100%;
  margin: auto;
}

.slider__clients__list figure img,
.slider__clients__list a img {
  max-width: 100%;
  height: auto;
  display: block;
}

@media screen and (max-width: 1300px) {
  .slider__clients__slider .slick-slider {
    padding: 0 25px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 767px) {
  .slider__clients__title {
    text-align: center;
  }
}

.slider__clients__slider ul {
  margin: 0;
  padding: 0;
}

.slider__clients__slider li {
  list-style: none;
  margin: 0;
}

.slider__clients__list figure,
.slider__clients__list a {
  display: block;
  max-width: 210px;
  width: 100%;
  margin: auto;
}

.slider__clients__list figure img,
.slider__clients__list a img {
  max-width: 100%;
  height: auto;
  display: block;
}

@media screen and (max-width: 1300px) {
  .slider__clients__slider .slick-slider {
    padding: 0 25px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 767px) {
  .slider__clients__title {
    text-align: center;
  }
}

.banner__internal {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 500px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative;
  padding: 40px 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.banner__internal:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 0.3)),
    to(rgba(0, 0, 0, 0.3))
  );
  background: linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  z-index: 1;
}

.banner__internal__container {
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.banner__internal__title {
  text-align: left;
}

.banner__internal__title h1,
.banner__internal__title h2 {
  font-family: 'ProximaNova-Semibold', sans-serif;
  font-weight: bold;
  font-size: 60px;
  color: #fff;
  margin: 0;
}

.banner__internal__title p {
  font-size: 15px;
  color: #fff;
  font-family: 'ProximaNova-Light', sans-serif;
  margin: 0;
  margin-top: 15px;
}

.banner__internal__bottom {
  padding-bottom: 0;
}

.banner__internal__bottom .banner__internal__title {
  margin-bottom: 65px;
}

@media screen and (max-width: 1024px) {
  .banner__internal__title h1,
  .banner__internal__title h2 {
    font-size: 50px;
  }
}

@media screen and (max-width: 768px) {
  .banner__internal {
    height: 400px;
  }
}

@media screen and (max-width: 480px) {
  .banner__internal {
    height: 350px;
  }
  .banner__internal--menu {
    height: 400px;
  }
  .banner__internal--menu .banner__internal__container {
    padding: 0;
  }
  .banner__internal--menu .banner__internal__title {
    margin-bottom: 30px;
    padding: 0 20px;
  }
  .g__links > ul {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .g__links > ul > li {
    width: 100%;
  }
  .g__links > ul > li > a {
    width: 100%;
    height: 100%;
  }
  .banner__internal__title h1,
  .banner__internal__title h2 {
    font-size: 40px;
  }
}

.slider__principal {
  overflow: hidden;
  height: 700px;
  position: relative;
}

.slider__principal__item {
  display: inline-block;
  position: relative;
  height: 700px;
  overflow: hidden;
}

.slider__principal__item__image {
  height: 700px;
  width: 100%;
  display: block;
  position: relative;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.slider__principal__container {
  max-width: 1240px;
  padding: 0 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: auto;
  height: 700px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
}

.slider__principal__list {
  opacity: 0;
  visibility: visible;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  margin: 0;
  padding: 0;
}

.slider__principal__list.slick-initialized {
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 1024px) {
  .slider__principal {
    height: 100vh;
  }
  .slider__principal__item {
    height: 100vh;
  }
  .slider__principal__container {
    height: 100vh;
  }
  .slider__principal__item__image {
    height: 100vh;
  }
}

@media screen and (max-width: 767px) {
  .slider__principal {
    height: 500px;
  }
  .slider__principal__item {
    height: 500px;
  }
  .slider__principal__container {
    height: 500px;
  }
  .slider__principal__item__image {
    height: 500px;
  }
}

@media screen and (max-width: 480px) {
  .slider__principal {
    height: 450px;
  }
  .slider__principal__item {
    height: 450px;
  }
  .slider__principal__container {
    height: 450px;
  }
  .slider__principal__item__image {
    height: 450px;
  }
}

.banner__principal__image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: block;
  position: relative;
}

.banner__principal__image:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.4;
}

.message__g {
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.message__g__container {
  max-width: 1040px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.message__g__info {
  width: 44%;
}

.message__g__logo {
  display: block;
  font-size: 0;
  max-width: 175px;
  width: 100%;
  margin-left: 0;
  margin-right: auto;
}

.message__g__logo img {
  max-width: 100%;
  height: auto;
  display: block;
}

.message__g__info__title h1 {
  line-height: 0.91;
  font-size: 45px;
  font-family: 'ProximaNova-Thin', sans-serif;
  margin: 0;
  color: #323e48;
}

.message__g__txt {
  font-size: 18px;
  font-family: 'ProximaNova-Thin', sans-serif;
  line-height: 1.28;
  color: #aeb4c3;
  margin-top: 35px;
}

.message__g__txt p {
  margin-bottom: 8px;
}

.message__g__button {
  margin-top: 41px;
  width: 163px;
  text-align: center;
}

.message__g__button span,
.message__g__button input {
  width: 100%;
}

.message__g__img {
  width: 50%;
  margin: 0;
  font-size: 0;
}

.message__g__img img {
  max-width: 100%;
  height: auto;
  display: block;
}

.message__g__img--resp {
  display: none;
}

.message__g--error .message__g__info {
  width: 43%;
  left: 23px;
  position: relative;
}

.message__g--error .message__g__container {
  max-width: 946px;
}

.message__g--error .message__g__img {
  width: 44.372%;
}

.message__g--enviado .message__g__info {
  width: 46%;
}

.message__g--enviado .message__g__txt {
  margin-top: 54px;
}

.message__g--enviado .message__g__container {
  max-width: 900px;
}

.message__g--enviado .message__g__img {
  width: 32.7%;
  position: relative;
  right: 16px;
}

@media screen and (max-width: 1024px) {
  .message__g {
    height: auto;
    padding: 100px 0;
  }
  .message__g__info__title h1 {
    font-size: 45px;
  }
  .message__g__container {
    max-width: 900px !important;
  }
  .message__g--error .message__g__info {
    left: 0;
  }
}

@media screen and (max-width: 768px) {
  .message__g--enviado .message__g__info {
    width: 100%;
  }
  .message__g--enviado .message__g__img {
    width: 100%;
    right: 0;
  }
  .message__g--enviado .message__g__txt {
    margin-top: 30px;
  }
  .message__g--enviado .message__g__img--resp {
    max-width: 328px;
  }
  .message__g--error .message__g__info {
    width: 100%;
  }
  .message__g--error .message__g__img {
    width: 100%;
  }
  .message__g--error .message__g__img--resp {
    max-width: 328px;
  }
  .message__g__logo {
    margin: auto;
  }
  .message__g__img {
    display: none;
  }
  .message__g__img--resp {
    display: block;
    font-size: 0;
  }
  .message__g__img--resp img {
    max-width: 100%;
    height: auto;
    display: block;
  }
  .message__g__container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .message__g__info__title h1 {
    font-size: 30px;
  }
  .message__g__txt {
    margin-top: 25px;
  }
  .message__g__info {
    width: 100%;
    text-align: center;
  }
  .message__g__button {
    margin-top: 40px;
  }
  .message__g__img {
    width: 100%;
    margin: auto;
    margin-top: 40px;
    max-width: 480px;
  }
}

@media screen and (max-width: 767px) {
  .message__g--enviado .message__g__img--resp {
    max-width: 300px;
  }
  .message__g--error .message__g__img--resp {
    max-width: 300px;
  }
}

.courier__banner {
  height: 580px;
  position: relative;
}

.courier__bg {
  position: absolute;
  top: -3px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 2;
}

.courier__trazado {
  position: absolute;
  left: 0;
  bottom: 60px;
  max-width: 100%;
  height: auto;
  z-index: 3;
}

.courier__banner__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;
  max-width: 1204px;
  z-index: 4;
}

.courier__banner__title__play {
  text-align: left;
  width: 34%;
}

.courier__banner__play {
  margin-top: 53px;
}

.courier__banner__play > span {
  color: #fff;
  font-size: 18px;
  font-family: 'ProximaNova-Thin', sans-serif;
  line-height: 1.2;
  display: block;
}

.courier__banner__play > section {
  margin: 0;
  margin-top: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.courier__banner__play > section > a {
  font-size: 0;
  display: block;
  margin: 0;
  max-width: 132px;
  width: 100%;
}

.courier__banner__play > section > a img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0;
}

.courier__banner__title .title__g__title b {
  font-weight: normal;
}

.courier__banner__img {
  margin: 0;
  padding-right: 305px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.courier__banner__form {
  position: absolute;
  top: 126px;
  right: 60px;
  padding: 52px 45px 37px 45px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 344px;
  background: #fff;
  border-radius: 12.4px;
  -webkit-box-shadow: 0 6.7px 7.7px 0 rgba(186, 195, 203, 0.75);
  box-shadow: 0 6.7px 7.7px 0 rgba(186, 195, 203, 0.75);
}

.courier__banner__form > h3 {
  max-width: 198px;
  margin: auto;
  width: 100%;
  font-family: 'ProximaNova-Light', sans-serif;
  font-size: 20px;
  color: #3e3e3e;
  line-height: 1.2;
  margin-bottom: 25px;
  text-align: center;
}

.courier__banner__form .input__g__line .input__g {
  margin-bottom: 15px;
}

.courier__banner__form .form__block__pie {
  margin-top: 10px;
  font-size: 13px;
  color: #323e48;
  font-family: 'ProximaNova-Thin', sans-serif;
}

.courier__banner__form .form__block__button {
  margin-top: 46px;
  text-align: center;
}

.courier__banner__form .form__block__button .g__button {
  width: 163px;
  text-align: center;
}

.courier__banner__form .form__block__button .g__button span,
.courier__banner__form .form__block__button .g__button input {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .courier__banner__title .title__g__title h1,
  .courier__banner__title .title__g__title h2,
  .courier__banner__title .title__g__title h3,
  .courier__banner__title .title__g__title h4 {
    font-size: 40px;
  }
  .courier__banner__title .title__g__title b {
    font-size: 40px;
  }
}

@media screen and (max-width: 960px) {
  .courier__banner__img {
    padding: 0;
  }
  .courier__banner__title__play {
    width: 44%;
  }
}

@media screen and (max-width: 768px) {
  .courier__banner__form {
    right: 20px;
  }
  .courier__banner__title .title__g__title h1,
  .courier__banner__title .title__g__title h2,
  .courier__banner__title .title__g__title h3,
  .courier__banner__title .title__g__title h4 {
    font-size: 36px;
  }
  .courier__banner__title .title__g__title b {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  .courier__banner__img {
    display: none;
  }
  .courier__banner {
    height: auto;
  }
  .courier__banner__container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .courier__banner__title__play {
    width: 100%;
    padding: 100px 0;
    text-align: center;
  }
  .courier__banner__form {
    position: static;
    width: 100%;
    margin-top: 36px;
    padding: 40px 30px;
  }
  .courier__banner__title .title__g__title h1,
  .courier__banner__title .title__g__title h2,
  .courier__banner__title .title__g__title h3,
  .courier__banner__title .title__g__title h4 {
    font-size: 35px;
  }
  .courier__banner__title .title__g__title b {
    font-size: 35px;
  }
}

.courier__optimiza {
  padding-top: 92px;
}

.courier__optimiza__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 70px;
  max-width: 1256px;
}

.courier__fig1 {
  position: absolute;
  top: -73%;
  right: -124%;
  z-index: 1;
}

.courier__fig2 {
  position: absolute;
  top: 0%;
  left: -106%;
  z-index: 1;
}

.courier__optimiza__img {
  margin: 0;
  width: 57.25%;
  font-size: 0;
  position: relative;
  z-index: 5;
}

.courier__optimiza__img img {
  max-width: 100%;
  height: auto;
  display: block;
}

.courier__optimiza__img .coruier-img {
  position: relative;
  z-index: 5;
  bottom: -30px;
}

.courier__optimiza__img .coruier-bg {
  position: absolute;
  bottom: 35px;
  z-index: 2;
  max-width: 100%;
}

.courier__optimiza__title {
  width: 30.264%;
  position: relative;
  z-index: 4;
  right: 105px;
  text-align: left;
}

@media screen and (max-width: 1200px) {
  .courier__optimiza__img .coruier-bg {
    max-width: 100%;
    position: absolute;
    top: 43%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    bottom: inherit;
  }
  .courier__fig1 {
    right: -140%;
  }
  .courier__fig2 {
    left: -116%;
  }
}

@media screen and (max-width: 1100px) {
  .courier__fig1 {
    right: -160%;
  }
  .courier__fig2 {
    left: -125%;
  }
  .courier__optimiza__title {
    width: 37%;
    right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .courier__fig1 {
    display: none;
  }
  .courier__fig2 {
    display: none;
  }
  .courier__optimiza__title .title__g__title h1,
  .courier__optimiza__title .title__g__title h2,
  .courier__optimiza__title .title__g__title h3,
  .courier__optimiza__title .title__g__title h4 {
    font-size: 35px;
  }
  .courier__optimiza__title .title__g__text {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .courier__optimiza {
    padding-top: 70px;
  }
  .courier__optimiza__img {
    width: 50%;
  }
  .courier__optimiza__title {
    width: 45%;
  }
  .courier__optimiza__title .title__g__title h1,
  .courier__optimiza__title .title__g__title h2,
  .courier__optimiza__title .title__g__title h3,
  .courier__optimiza__title .title__g__title h4 {
    font-size: 28px;
  }
  .courier__optimiza__title .title__g__text {
    font-size: 18px;
  }
  .courier__optimiza__img .coruier-bg {
    top: 55%;
  }
}

@media screen and (max-width: 767px) {
  .courier__optimiza {
    padding-top: 0;
  }
  .courier__optimiza__img {
    display: none;
  }
  .courier__optimiza__title {
    width: 100%;
    text-align: center;
  }
  .courier__banner__play > section {
    justify-content: center;
  }
}

.courier__list {
  padding-top: 35px;
  position: relative;
  z-index: 5;
}

.courier__list__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  padding-top: 35px;
  max-width: 1210px;
  text-align: left;
}

.courier__list__container:before {
  content: '';
  position: absolute;
  top: 0;
  max-width: 1000px;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  height: 1px;
  background: rgba(0, 0, 0, 0.05);
}

.courier__list__container article {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 25%;
}

.courier__list__container article > figure {
  margin: 0;
  width: 65px;
  font-size: 0;
}

.courier__list__container article > figure img {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: 0;
  margin-right: auto;
}

.courier__list__container article > figcaption {
  width: calc(100% - 65px);
  padding-left: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.courier__list__container article > figcaption > h3 {
  line-height: 1.2;
  font-size: 20px;
  color: #323e48;
  font-family: 'ProximaNova-Thin', sans-serif;
  margin: 0;
  margin-bottom: 15px;
}

.courier__list__container article > figcaption > section {
  line-height: 1.2;
  font-size: 14px;
  color: #aeb4c3;
  font-family: 'ProximaNova-Thin', sans-serif;
}

@media screen and (max-width: 768px) {
  .courier__list {
    padding-top: 80px;
  }
  .courier__list__container article {
    width: 30%;
  }
}

@media screen and (max-width: 767px) {
  .courier__list {
    padding-top: 31px;
  }
  .courier__list__container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .courier__list__container article {
    width: 100%;
    max-width: 298px;
    margin: auto;
    margin-bottom: 15px;
  }
  .courier__list__container article:last-child {
    margin-bottom: 0;
  }
}

.coruier__mensajero {
  padding-top: 160px;
}

.coruier__mensajero__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 1340px;
}

.coruier__mensajero__info {
  width: 30%;
  position: relative;
  left: 50px;
  z-index: 5;
}

.coruier__mensajero__img {
  width: 57%;
  font-size: 0;
  margin: 0;
  position: relative;
  z-index: 5;
}

.coruier__mensajero__img img {
  max-width: 100%;
  height: auto;
  display: block;
}

.coruier__mensajero__list {
  margin-top: 68px;
  text-align: left;
}

.coruier__mensajero__list > ul > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: 0;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 45px;
}

.coruier__mensajero__list > ul > li:last-child {
  margin-bottom: 0;
}

.coruier__mensajero__list > ul > li > span {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 100%;
  background: #635daf;
  color: #fff;
  margin: 0;
  text-align: center;
  font-size: 11px;
}

.coruier__mensajero__list > ul > li > span:before {
  color: #fff;
}

.coruier__mensajero__list > ul > li > section {
  width: calc(100% - 30px);
  font-family: 'ProximaNova-Thin', sans-serif;
  color: #aeb4c3;
  font-size: 18px;
  line-height: 1.2;
  margin: 0;
  padding-left: 19px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.coruier__mensajero__list > ul > li > section p {
  margin: 0;
}

.coruier__mensajero__img--resp {
  display: none;
}

@media screen and (max-width: 1024px) {
  .coruier__mensajero__info {
    left: 0;
    width: 40%;
  }
  .coruier__mensajero__container {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .coruier__mensajero__title .title__g__title h1,
  .coruier__mensajero__title .title__g__title h2,
  .coruier__mensajero__title .title__g__title h3,
  .coruier__mensajero__title .title__g__title h4 {
    font-size: 35px;
  }
  .coruier__mensajero__title .title__g__text {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .coruier__mensajero {
    padding-top: 90px;
  }
  .coruier__mensajero__title .title__g__title h1,
  .coruier__mensajero__title .title__g__title h2,
  .coruier__mensajero__title .title__g__title h3,
  .coruier__mensajero__title .title__g__title h4 {
    font-size: 28px;
  }
  .coruier__mensajero__title .title__g__text {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .coruier__mensajero {
    padding-top: 52px;
  }
  .coruier__mensajero__info {
    width: 100%;
    text-align: center;
  }
  .coruier__mensajero__img {
    display: none;
  }
  .coruier__mensajero__img--resp {
    display: block;
    margin-top: 35px;
  }
  .coruier__mensajero__img--resp img {
    max-width: 100%;
    height: auto;
    display: block;
  }
  .coruier__mensajero__list > ul > li {
    margin-bottom: 34px;
  }
  .coruier__mensajero__list {
    margin-top: 40px;
  }
}

.coruier__test {
  padding-top: 145px;
  padding-bottom: 60px;
}

.coruier__test__container {
  max-width: 1396px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.coruier__test__test__list__img {
  position: relative;
}

.coruier__test__test__list__img > .coruier-test-bg {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0;
}

.coruier__test__test__list ul {
  font-size: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.coruier__test__test__list > ul li {
  width: 100%;
  max-width: 267px;
  margin: 2% 2%;
  display: inline-block;
  vertical-align: top;
}

.coruier__test__test__list article {
  margin: 0;
  background: #fff;
  border-radius: 10.9px;
  padding: 25px 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 5.9px 6.7px 0 rgba(50, 62, 72, 0.3);
  box-shadow: 0 5.9px 6.7px 0 rgba(50, 62, 72, 0.3);
  position: relative;
  text-align: left;
}

.coruier__test__test__list article > i {
  font-size: 49px;
  position: absolute;
  top: 30px;
  right: 29px;
  opacity: 0.11;
}

.coruier__test__test__list article > figure {
  width: 50px;
  height: 50px;
  margin-left: 0;
  margin-right: auto;
  font-size: 0;
  margin-bottom: 24px;
}

.coruier__test__test__list article > figure > img {
  max-width: 100%;
  height: auto;
  display: block;
}

.coruier__test__test__list article > span {
  display: block;
  margin: 0;
  margin-top: 13px;
}

.coruier__test__test__list article > span p {
  font-family: 'ProximaNova-Regular', sans-serif;
  font-size: 14px;
  color: #323e48;
  line-height: 1.53;
  margin: 0;
}

.coruier__test__test__list article > span i {
  margin: 0;
  margin-top: 7px;
  font-style: normal;
  font-family: 'ProximaNova-Thin', sans-serif;
  color: #4cce92;
  font-size: 13px;
}

.coruier__test__test__list article > figcaption {
  font-size: 14px;
  font-family: 'ProximaNova-Thin', sans-serif;
  color: #aeb4c3;
  line-height: 1.2;
}

.coruier__test__test__list {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.coruier__test__test__list_i {
  margin: 0;
}

.coruier__test__test__list_i.one {
  position: relative;
  top: 89px;
}

.coruier__test__test__list_i.two {
  margin-top: 3.5%;
}

.coruier__test__test__list_i.three {
  position: relative;
  top: 80px;
}

.coruier__test__mobile {
  display: none;
}

.coruier__test__mobile ul {
  font-size: 0;
}

.coruier__test__mobile li {
  margin: 0 10px;
  display: inline-block;
  vertical-align: top;
}

.coruier__test__mobile article {
  margin: 0;
  background: #fff;
  border-radius: 10.9px;
  padding: 25px 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 5.9px 6.7px 0 #c7d9e8;
  box-shadow: 0 5.9px 6.7px 0 #c7d9e8;
}

.coruier__test__mobile article > figure {
  width: 50px;
  height: 50px;
  margin: 0;
  margin-left: 0;
  margin-right: auto;
  font-size: 0;
  margin-bottom: 24px;
}

.coruier__test__mobile article > figure > img {
  max-width: 100%;
  height: auto;
  display: block;
}

.coruier__test__mobile article > span {
  display: block;
  margin: 0;
  margin-top: 13px;
}

.coruier__test__mobile article > span p {
  font-family: 'ProximaNova-Regular', sans-serif;
  font-size: 14px;
  color: #323e48;
  line-height: 1.53;
  margin: 0;
}

.coruier__test__mobile article > span i {
  margin: 0;
  margin-top: 7px;
  font-style: normal;
  font-family: 'ProximaNova-Thin', sans-serif;
  color: #4cce92;
  font-size: 13px;
}

.coruier__test__mobile article > figcaption {
  font-size: 14px;
  font-family: 'ProximaNova-Thin', sans-serif;
  color: #aeb4c3;
  line-height: 1.2;
}

.coruier__test__test__list__img {
  width: 57.82%;
}

.coruier__test__test__text {
  width: 31%;
  right: 125px;
  top: 50px;
  position: relative;
}

.coruier__test__test__text .title__g2 figure {
  width: 68px;
  height: 68px;
}

.coruier__test__test__text .title__g2 .title__g__title__text {
  width: calc(100% - 68px);
}

.coruier__test__test__text .title__g2 .title__g__title__text .title__g__text {
  margin-top: 26px;
}

@media screen and (max-width: 1300px) {
  .coruier__test__test__text {
    right: 0;
  }
  .coruier__test__test__text {
    width: 40%;
  }
}

@media screen and (max-width: 1100px) {
  .coruier__test__test__list__img {
    width: 64%;
  }
  .coruier__test__test__text {
    width: 36%;
  }
  .coruier__test__test__list > ul li {
    width: 46%;
    margin: 2%;
  }
}

@media screen and (max-width: 1024px) {
  .coruier__test__test__text .title__g__title h1,
  .coruier__test__test__text .title__g__title h2,
  .coruier__test__test__text .title__g__title h3,
  .coruier__test__test__text .title__g__title h4 {
    font-size: 35px;
  }
  .coruier__test__test__text .title__g__text {
    font-size: 18px;
  }
}

@media screen and (max-width: 960px) {
  .coruier__test__test__list > ul li {
    max-width: 100%;
  }
  .coruier__test__test__list > ul li {
    display: none;
  }
  .coruier__test__test__list > ul li:nth-child(1) {
    display: inline-block;
  }
  .coruier__test__test__list > ul li:nth-child(2) {
    display: inline-block;
  }
  .coruier__test__test__list_i.one {
    top: 50px;
  }
  .coruier__test__test__list__img {
    width: 58%;
  }
  .coruier__test__test__text {
    width: 42%;
  }
}

@media screen and (max-width: 768px) {
  .coruier__test {
    padding-top: 90px;
  }
  .coruier__test__test__text .title__g__title h1,
  .coruier__test__test__text .title__g__title h2,
  .coruier__test__test__text .title__g__title h3,
  .coruier__test__test__text .title__g__title h4 {
    font-size: 28px;
  }
  .coruier__test__test__text .title__g__text {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .coruier__test__test__list__img > .coruier-test-bg {
    display: none;
  }
  .coruier__test__test__list > ul li {
    margin: 0 10px;
  }
  .coruier__test__mobile {
    display: block;
    width: 100%;
  }
  .coruier__test__mobile.dots__g__slider .slick-dots button {
    width: 12px;
    height: 12px;
    border: 1px solid #c7d9e8;
    background: transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    opacity: 1;
  }
  .coruier__test__mobile.dots__g__slider .slick-dots li.slick-active button {
    background: #c7d9e8;
  }
  .coruier__test__mobile.dots__g__slider .slick-slider {
    padding-bottom: 30px;
  }
  .coruier__test__test__text .title__g2 .title__g__title__text .title__g__text {
    margin-top: 13px;
  }
  .coruier__test__test__list {
    position: static;
  }
  .coruier__test__desktop {
    display: none !important;
  }
  .coruier__test__mobile .slick-slide {
    padding: 15px 0;
  }
  .coruier__test__test__list > ul li {
    margin: 0 !important;
    padding: 15px 0;
  }
  .coruier__test__container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .coruier__test__test__list__img {
    width: 100%;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .coruier__test__test__text {
    width: 100%;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-bottom: 35px;
  }
  .coruier__test__test__list__img > .coruier-test-bg {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0;
    position: absolute;
    width: 399px;
    height: 300px;
    left: -139px;
    top: -25px;
  }
  .coruier__test__mobile article {
    height: 302px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .coruier__test__test__text {
    top: 0;
  }
}

.coruier__app {
  padding-top: 130px;
}

.coruier__app__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  max-width: 1106px;
}

.coruier__app__img1 {
  position: absolute;
  top: -74%;
  right: -132%;
}

.coruier__app__img2 {
  position: absolute;
  top: -100%;
  left: -128%;
}

.coruier__app__playstore {
  width: 35%;
  padding-bottom: 120px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;
}

.coruier__app__playstore .title__g .title__g__text {
  margin-top: 34px;
  line-height: 1.31;
}

.coruier__app__playstore .title__g .title__g__text strong {
  font-weight: normal;
}

.coruier__playstore {
  margin-top: 45px;
}

.coruier__playstore > span {
  font-family: 'ProximaNova-Thin', sans-serif;
  font-size: 18px;
  color: #aeb4c3;
  margin: 0;
  display: block;
  margin-bottom: 30px;
}

.coruier__playstore > section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.coruier__playstore > section > a {
  display: block;
  font-size: 0;
  margin: 0;
  max-width: 132px;
  width: 100%;
  margin-right: 15px;
}

.coruier__playstore > section > a img {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: 0;
  margin-right: auto;
}

.coruier__app__image {
  font-size: 0;
  width: 63%;
}

.coruier__app__image img {
  max-width: 100%;
  height: auto;
  display: block;
}

@media screen and (max-width: 1024px) {
  .coruier__app__playstore .title__g .title__g__text {
    font-size: 18px;
  }
  .coruier__app__playstore .title__g .title__g__title h1,
  .coruier__app__playstore .title__g .title__g__title h2,
  .coruier__app__playstore .title__g .title__g__title h3,
  .coruier__app__playstore .title__g .title__g__title h4 {
    font-size: 35px;
  }
  .coruier__app__playstore .title__g .title__g__title h1 strong,
  .coruier__app__playstore .title__g .title__g__title h2 strong,
  .coruier__app__playstore .title__g .title__g__title h3 strong,
  .coruier__app__playstore .title__g .title__g__title h4 strong {
    color: #4cce92;
  }
}

@media screen and (max-width: 1020px) {
  .coruier__app__img2 {
    display: none;
  }
  .coruier__app__img1 {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .coruier__app {
    padding-top: 100px;
  }
  .coruier__app__playstore .title__g .title__g__text {
    font-size: 18px;
  }
  .coruier__app__playstore .title__g .title__g__title h1,
  .coruier__app__playstore .title__g .title__g__title h2,
  .coruier__app__playstore .title__g .title__g__title h3,
  .coruier__app__playstore .title__g .title__g__title h4 {
    font-size: 28px;
  }
  .coruier__app__playstore {
    padding-bottom: 0;
  }
  .coruier__app__container {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .coruier__app__container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .coruier__app__playstore {
    width: 100%;
    padding-bottom: 0;
  }
  .coruier__app__image {
    width: 100%;
  }
  .coruier__playstore {
    display: none;
  }
  .coruier__app {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .coruier__app__playstore .title__g .title__g__title {
    text-align: center;
  }
}

.coruier__bottom {
  padding-top: 141px;
  padding-bottom: 86px;
}

.coruier__bottom__title {
  max-width: 810px;
  width: 100%;
  margin: auto;
  text-align: center;
}

.coruier__bottom__title .title__g__button {
  margin-top: 61px;
}

@media screen and (max-width: 1024px) {
  .coruier__bottom__title .title__g__text {
    font-size: 18px;
  }
  .coruier__bottom__title .title__g__title h1,
  .coruier__bottom__title .title__g__title h2,
  .coruier__bottom__title .title__g__title h3,
  .coruier__bottom__title .title__g__title h4 {
    font-size: 35px;
  }
}

@media screen and (max-width: 768px) {
  .coruier__bottom {
    padding-top: 100px;
    padding-bottom: 80px;
  }
  .coruier__bottom__title .title__g__text {
    font-size: 18px;
  }
  .coruier__bottom__title .title__g__title h1,
  .coruier__bottom__title .title__g__title h2,
  .coruier__bottom__title .title__g__title h3,
  .coruier__bottom__title .title__g__title h4 {
    font-size: 28px;
  }
  .coruier__bottom__container {
    max-width: 500px;
  }
}

@media screen and (max-width: 767px) {
  .coruier__bottom {
    display: none;
  }
}

.contact__block {
  padding-top: 100px;
  padding-bottom: 141px;
}

.contact__block__container {
  max-width: 1172px;
}

.contact__block__img1 {
  position: absolute;
  top: 20%;
  right: -114%;
  pointer-events: none;
}

.contact__block__img2 {
  position: absolute;
  top: -59%;
  left: -100%;
  pointer-events: none;
}

.contact__block__form__title {
  max-width: 510px;
  margin: auto;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.contact__block__form__title .title__g__text {
  margin-top: 8px;
}

.contact__block__form__links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 76px;
}

.contact__block__form {
  width: 56%;
  background: #fff;
  border-radius: 14.5px;
  -webkit-box-shadow: 0 9px 13px 0 rgba(215, 231, 245, 0.55);
  box-shadow: 0 9px 13px 0 rgba(215, 231, 245, 0.55);
  border: solid 1px #e4e7e8;
  padding: 69px 49px 38px 49px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  z-index: 4;
}

.contact__block__form .form__block__pie {
  font-family: 'ProximaNova-Thin', sans-serif;
  font-size: 11px;
  color: #797f80;
}

.contact__block__form .form__block__button {
  margin-top: 40px;
  text-align: center;
}

.contact__block__form .form__block__button .button__g {
  width: 137px;
  text-align: center;
}

.contact__block__form .form__block__button .button__g span,
.contact__block__form .form__block__button .button__g input {
  width: 100%;
}

.contact__block__links__social {
  width: 30%;
  padding-top: 70px;
  position: relative;
  z-index: 4;
}

.contact__block__form__intitle {
  text-align: left;
  margin-bottom: 30px;
}

.contact__block__form__intitle .title__g__text {
  margin-top: 8px;
}

.contact__block__links > article {
  margin-bottom: 52px;
}

.contact__block__links > article:last-child {
  margin-bottom: 0;
}

.contact__block__links > article > h3 {
  font-family: 'ProximaNova-Light', sans-serif;
  color: #4cce92;
  font-size: 20px;
  margin: 0;
  margin-bottom: 12px;
}

.contact__block__links > article > section > a,
.contact__block__links > article > section p {
  display: block;
  margin: 0;
  font-family: 'ProximaNova-Thin', sans-serif;
  font-size: 18px;
  color: #aeb4c3;
  line-height: 1.25;
  margin-bottom: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.contact__block__links > article > section > a:last-child,
.contact__block__links > article > section p:last-child {
  margin-bottom: 0;
}

.contact__block__links > article > section > a > i,
.contact__block__links > article > section p > i {
  margin-right: 10px;
  font-size: 16px;
}

.contact__block__links > article > section > a:nth-child(2) > i,
.contact__block__links > article > section p:nth-child(2) > i {
  font-size: 22px;
}

.contact__block__social {
  margin-top: 69px;
}

.contact__block__social > span {
  font-size: 20px;
  font-family: 'ProximaNova-Thin', sans-serif;
  color: #323e48;
}

.contact__block__social > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 18px;
}

.contact__block__social > ul > li {
  margin: 0;
  margin-right: 15px;
}

.contact__block__social > ul > li:last-child {
  margin-right: 0;
}

.contact__block__social > ul > li > a {
  font-size: 25px;
  color: #a1b4b7;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.contact__block__social > ul > li > a:before {
  color: #a1b4b7;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.contact__block__social > ul > li > a:hover {
  color: #4cce92;
}

.contact__block__social > ul > li > a:hover:before {
  color: #4cce92;
}

@media screen and (max-width: 1200px) {
  .contact__block__img1 {
    right: -132%;
  }
}

@media screen and (max-width: 1024px) {
  .contact__block__form {
    width: 60%;
  }
  .contact__block__links__social {
    width: 30%;
  }
  .contact__block {
    padding-top: 65px;
    padding-bottom: 65px;
  }
  .contact__block__form__links {
    margin-top: 50px;
  }
}

@media screen and (max-width: 1020px) {
  .contact__block__img1 {
    display: none;
  }
  .contact__block__img2 {
    display: none;
  }
  .contact__block__form__title .title__g__text {
    font-size: 18px;
  }
  .contact__block__form__title .title__g__title h1,
  .contact__block__form__title .title__g__title h2,
  .contact__block__form__title .title__g__title h3,
  .contact__block__form__title .title__g__title h4 {
    font-size: 35px;
  }
}

@media screen and (max-width: 768px) {
  .contact__block__form .input__g--two-columns .input__g {
    width: 100% !important;
    margin-right: 0 !important;
  }
  .contact__block__form__title .title__g__text {
    font-size: 18px;
  }
  .contact__block__form__title .title__g__title h1,
  .contact__block__form__title .title__g__title h2,
  .contact__block__form__title .title__g__title h3,
  .contact__block__form__title .title__g__title h4 {
    font-size: 30px;
  }
}

@media screen and (max-width: 767px) {
  .contact__block__form {
    width: 100%;
  }
  .contact__block__links__social {
    width: 100%;
  }
  .contact__block {
    padding-top: 65px;
    padding-bottom: 65px;
  }
  .contact__block__form {
    padding: 40px 20px;
  }
  .contact__block__form__links {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .contact__block__form__links {
    margin-top: 40px;
  }
  .contact__block__links__social {
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.home__inall {
  overflow: hidden;
}

.home__banner__all {
  text-align: left;
}

.home__banner {
  height: 713px;
}

.home__banner__container {
  max-width: 1200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 3;
}

.home__banner__bg {
  position: absolute;
  bottom: 0;
  right: -35%;
  max-width: 100%;
  height: auto;
  display: block;
}

.home__banner__text {
  width: 38%;
}

.home__banner__text .title__g .title__g__title h1 {
  display: inline-block;
}

.home__banner__text .title__g .title__g__title p {
  display: inline-block;
}

.home__banner__text .title__g__text {
  max-width: 406px;
  margin-left: 0;
  margin-right: auto;
  margin-top: 39px;
}

.home__banner__text .title__g__button .button__g {
  width: 177px;
  text-align: center;
}

.home__banner__text .title__g__button .button__g span,
.home__banner__text .title__g__button .button__g input {
  width: 100%;
}

.home__banner__imgs__all {
  position: relative;
}

.home__banner__imgs {
  position: relative;
  z-index: 4;
  right: 30px;
  top: 20px;
}

.home__bannerfig1,
.home__bannerfig2,
.home__bannerfig3,
.home__bannerfig4,
.home__bannerfig4,
.home__bannerfig5,
.home__bannerfig6,
.home__bannerfig7,
.home__bannerfig8,
.home__bannerfig9,
.home__bannerfig11,
.home__bannerfig12,
.home__bannerfig13,
.home__bannerfig14,
.home__bannerfig15,
.home__bannerfig16,
.home__bannerfig17,
.home__bannerfig18,
.home__bannerfig19,
.home__bannerfig20 {
  position: absolute;
}

.home__bannerfig3 {
  top: -18%;
  right: 15%;
  width: 29.8%;
}

.home__bannerfig4 {
  bottom: 65%;
  width: 13.527%;
  right: -8%;
}

.home__bannerfig5 {
  bottom: 44%;
  width: 14%;
  left: -7%;
}

.home__bannerfig6 {
  right: 41%;
  top: -34%;
  width: 14.9%;
}

.home__bannerfig7 {
  top: -34%;
  right: 53%;
  width: 19%;
}

.home__bannerfig8 {
  top: -8%;
  right: 64.7%;
  width: 14.85%;
}

.home__bannerfig9 {
  width: 29.793%;
  left: 53%;
  bottom: 3%;
}

.home__bannerfig10 {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  z-index: -1;
}

.home__bannerfig11 {
  right: 2%;
  top: 40%;
  width: 4%;
  z-index: 3;
}

.home__bannerfig12 {
  right: -1%;
  top: 39%;
  width: 5%;
  z-index: 3;
}

.home__bannerfig14 {
  right: 12%;
  top: 11%;
  width: 13%;
}

.home__bannerfig17 {
  bottom: 16%;
  left: 20%;
  width: 15%;
}

.home__bannerfig16 {
  right: 19%;
  top: 22%;
  width: 9.7%;
}

.home__bannerfig18 {
  top: 49%;
  left: 52%;
  width: 17.1%;
  z-index: 3;
}

.home__bannerfig19 {
  left: 1%;
  bottom: 0%;
  width: 17.232%;
}

.home__bannerfig20 {
  bottom: -15%;
  left: 39%;
  width: 24%;
  z-index: 2;
}

.home__bannerfig22 {
  position: absolute;
  right: 34%;
  top: 25%;
  width: 15.8%;
}

.home__bannerfig23 {
  position: absolute;
  right: 47%;
  top: 41%;
  width: 22.3%;
}

.home__bannerfig24 {
  position: absolute;
  right: 57%;
  top: 64%;
  width: 2.255%;
}

.home__bannerfig25 {
  position: absolute;
  right: 53%;
  top: 59.5%;
  width: 2.255%;
}

.home__bannerfig26 {
  position: absolute;
  right: 49%;
  top: 55%;
  width: 2.255%;
}

.home__bannerfig27 {
  position: absolute;
  left: 14%;
  bottom: -14%;
  width: 25.766%;
}

.home__bannerfig28 {
  position: absolute;
  left: 18%;
  bottom: -12%;
  width: 15.944%;
}

.home__bannerfig29 {
  position: absolute;
  left: -10%;
  bottom: 19%;
  width: 14%;
}

.home__bannerfig30 {
  position: absolute;
  bottom: 28%;
  right: 12%;
  width: 13.53%;
}

.home__bannerfig31 {
  right: 0;
  bottom: 38%;
  position: absolute;
  width: 6.765%;
}

.home__bannerfig32 {
  position: absolute;
  right: -10%;
  bottom: 58%;
  width: 2.255%;
}

.home__bannerfig33 {
  position: absolute;
  right: -6%;
  bottom: 55%;
  width: 2.255%;
}

.home__bannerfig34 {
  position: absolute;
  right: -8%;
  bottom: 50%;
  width: 2.255%;
}

.home__bannerfig35 {
  position: absolute;
  right: -12%;
  bottom: 53%;
  width: 2.255%;
}

.home__bannerfig36 {
  position: absolute;
  top: 41%;
  left: 23.5%;
  width: 12.4%;
}

.home__bannerfig37 {
  position: absolute;
  top: 29%;
  right: 33%;
  width: 12.4%;
}

.home__bannerfig38 {
  position: absolute;
  top: 10%;
  right: -5%;
  width: 14.815%;
}

@-webkit-keyframes figcar1 {
  0% {
    -webkit-transform: translatex(0px) translatey(0px);
    transform: translatex(0px) translatey(0px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translatex(80%) translatey(58%);
    transform: translatex(80%) translatey(58%);
    opacity: 1;
  }
}

@keyframes figcar1 {
  0% {
    -webkit-transform: translatex(0px) translatey(0px);
    transform: translatex(0px) translatey(0px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translatex(80%) translatey(58%);
    transform: translatex(80%) translatey(58%);
    opacity: 1;
  }
}

.home__bannerfig17 {
  bottom: 36%;
  left: 3%;
}

.home__bannerfig17 {
  opacity: 0;
  -webkit-animation: figcar1 2s alternate forwards 3.3s;
  animation: figcar1 2s alternate forwards 3.3s;
}

@-webkit-keyframes figcar-active {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes figcar-active {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.home__bannerfig36 {
  opacity: 0;
  -webkit-animation: figcar-active 1s alternate forwards 3.8s;
  animation: figcar-active 1s alternate forwards 3.8s;
}

@-webkit-keyframes figcar2 {
  0% {
    -webkit-transform: translatex(0px) translatey(0px);
    transform: translatex(0px) translatey(0px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translatex(109%) translatey(-86%);
    transform: translatex(109%) translatey(-86%);
    opacity: 1;
  }
}

@keyframes figcar2 {
  0% {
    -webkit-transform: translatex(0px) translatey(0px);
    transform: translatex(0px) translatey(0px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translatex(109%) translatey(-86%);
    transform: translatex(109%) translatey(-86%);
    opacity: 1;
  }
}

.home__bannerfig18 {
  top: 68%;
  left: 32%;
}

.home__bannerfig18 {
  opacity: 0;
  -webkit-animation: figcar2 2s alternate forwards 4.4s;
  animation: figcar2 2s alternate forwards 4.4s;
}

@-webkit-keyframes figcar-active2 {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes figcar-active2 {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.home__bannerfig37 {
  opacity: 0;
  -webkit-animation: figcar-active2 1s alternate forwards 5.1s;
  animation: figcar-active2 1s alternate forwards 5.1s;
}

@-webkit-keyframes figcar3 {
  0% {
    -webkit-transform: translatex(0px) translatey(0px);
    transform: translatex(0px) translatey(0px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translatex(130%) translatey(62%);
    transform: translatex(130%) translatey(62%);
    opacity: 1;
  }
}

@keyframes figcar3 {
  0% {
    -webkit-transform: translatex(0px) translatey(0px);
    transform: translatex(0px) translatey(0px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translatex(130%) translatey(62%);
    transform: translatex(130%) translatey(62%);
    opacity: 1;
  }
}

.home__bannerfig16 {
  right: 31%;
  top: 10%;
}

.home__bannerfig16 {
  opacity: 0;
  -webkit-animation: figcar3 2s alternate forwards 5.5s;
  animation: figcar3 2s alternate forwards 5.5s;
}

@-webkit-keyframes figcar-active3 {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes figcar-active3 {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.home__bannerfig38,
.home__bannerfig11,
.home__bannerfig12 {
  opacity: 0;
  -webkit-animation: figcar-active3 1s alternate forwards 6s;
  animation: figcar-active3 1s alternate forwards 6s;
}

@-webkit-keyframes figcar-init {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes figcar-init {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.home__bannerfig10 {
  opacity: 0;
  -webkit-animation: figcar-init 2s alternate forwards 2.5s;
  animation: figcar-init 2s alternate forwards 2.5s;
}

@-webkit-keyframes figcar-all-edificios {
  0% {
    -webkit-transform: scale(0.5) translatey(150px);
    transform: scale(0.5) translatey(150px);
    opacity: 0;
  }
  20% {
    -webkit-transform: scale(1) translatey(0);
    transform: scale(1) translatey(0);
    opacity: 1;
  }
  40% {
    -webkit-transform: scale(1) translatey(5px);
    transform: scale(1) translatey(5px);
    opacity: 1;
  }
  60% {
    -webkit-transform: scale(1) translatey(0);
    transform: scale(1) translatey(0);
    opacity: 1;
  }
  80% {
    -webkit-transform: scale(1) translatey(5px);
    transform: scale(1) translatey(5px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1) translatey(0);
    transform: scale(1) translatey(0);
    opacity: 1;
  }
}

@keyframes figcar-all-edificios {
  0% {
    -webkit-transform: scale(0.5) translatey(150px);
    transform: scale(0.5) translatey(150px);
    opacity: 0;
  }
  20% {
    -webkit-transform: scale(1) translatey(0);
    transform: scale(1) translatey(0);
    opacity: 1;
  }
  40% {
    -webkit-transform: scale(1) translatey(5px);
    transform: scale(1) translatey(5px);
    opacity: 1;
  }
  60% {
    -webkit-transform: scale(1) translatey(0);
    transform: scale(1) translatey(0);
    opacity: 1;
  }
  80% {
    -webkit-transform: scale(1) translatey(5px);
    transform: scale(1) translatey(5px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1) translatey(0);
    transform: scale(1) translatey(0);
    opacity: 1;
  }
}

.home__bannerfig31,
.home__bannerfig6,
.home__bannerfig5 {
  opacity: 0;
  -webkit-animation: figcar-all-edificios 3s alternate forwards 1s;
  animation: figcar-all-edificios 3s alternate forwards 1s;
}

.home__bannerfig22,
.home__bannerfig7,
.home__bannerfig33 {
  opacity: 0;
  -webkit-animation: figcar-all-edificios 3s alternate forwards 1.4s;
  animation: figcar-all-edificios 3s alternate forwards 1.4s;
}

.home__bannerfig23,
.home__bannerfig20,
.home__bannerfig4 {
  opacity: 0;
  -webkit-animation: figcar-all-edificios 3s alternate forwards 1.7s;
  animation: figcar-all-edificios 3s alternate forwards 1.7s;
}

.home__bannerfig3,
.home__bannerfig32,
.home__bannerfig8 {
  opacity: 0;
  -webkit-animation: figcar-all-edificios 3s alternate forwards 1.9s;
  animation: figcar-all-edificios 3s alternate forwards 1.9s;
}

.home__bannerfig29,
.home__bannerfig26,
.home__bannerfig9 {
  opacity: 0;
  -webkit-animation: figcar-all-edificios 3s alternate forwards 2s;
  animation: figcar-all-edificios 3s alternate forwards 2s;
}

.home__bannerfig19,
.home__bannerfig30,
.home__bannerfig27 {
  opacity: 0;
  -webkit-animation: figcar-all-edificios 3s alternate forwards 1.1s;
  animation: figcar-all-edificios 3s alternate forwards 1.1s;
}

.home__bannerfig14,
.home__bannerfig34,
.home__bannerfig35 {
  opacity: 0;
  -webkit-animation: figcar-all-edificios 3s alternate forwards 1.6s;
  animation: figcar-all-edificios 3s alternate forwards 1.6s;
}

.home__bannerfig24,
.home__bannerfig25,
.home__bannerfig28 {
  opacity: 0;
  -webkit-animation: figcar-all-edificios 3s alternate forwards 1.4s;
  animation: figcar-all-edificios 3s alternate forwards 1.4s;
}

.home__bannerfig14,
.home__bannerfig34,
.home__bannerfig35 {
  opacity: 0;
  -webkit-animation: figcar-all-edificios 3s alternate forwards 1.2s;
  animation: figcar-all-edificios 3s alternate forwards 1.2s;
}

.home__banner__bbuton {
  display: none;
}

.home__banner__bg__resp {
  display: none;
}

@media screen and (max-width: 1024px) {
  .home__banner__imgs__all {
    width: 53%;
  }
}

@media screen and (max-width: 1020px) {
  .home__banner {
    height: 820px;
  }
  .home__banner__container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .home__banner__text {
    width: 100%;
    text-align: center;
  }
  .home__banner__text .title__g__text {
    margin: auto;
    margin-top: 5px;
  }
  .home__banner__text .title__g__button {
    display: none;
  }
  .home__banner__imgs__all {
    width: 100%;
    padding: 0 10% 0 16%;
  }
  .home__banner__container {
    max-width: 100%;
  }
  .home__banner__bg {
    right: -18%;
  }
  .home__banner__bbuton {
    display: block;
    text-align: center;
    padding-top: 45px;
  }
  .home__banner__bbuton .button__g {
    width: 162px;
  }
  .home__banner__bbuton .button__g span,
  .home__banner__bbuton .button__g input {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 1024px) {
  .home__banner__text .title__g .title__g__title h1 {
    font-size: 40px;
  }
  .home__banner__text .title__g .title__g__title p {
    font-size: 40px;
  }
}

@media screen and (max-width: 768px) {
  .home__banner {
    height: 714px;
  }
  .home__banner__text .title__g__text {
    max-width: 381px;
  }
  .home__banner__text .title__g .title__g__title h1 {
    font-size: 36px;
  }
  .home__banner__text .title__g .title__g__title p {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  .home__banner__bg {
    display: none;
  }
  .home__banner {
    height: 700px;
  }
  .home__banner__text .title__g .title__g__title h1 {
    font-size: 30px;
    display: block;
    line-height: 1;
    color: #fff;
    font-family: 'ProximaNova-Thin', sans-serif;
  }
  .home__banner__text .title__g .title__g__title p {
    font-size: 30px;
    display: block;
    line-height: 1;
    color: #fff;
  }
  .home__banner__text .title__g .title__g__text {
    line-height: 1;
    font-size: 18px;
    margin-top: 14px;
    color: #fff;
  }
  .home__banner__bg__resp {
    overflow: hidden;
    display: block;
  }
  .home__banner__bg__resp img {
    display: block;
    position: absolute;
    max-width: inherit;
    top: -230px;
    width: 1020px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .home__banner__text {
    padding-top: 40px;
  }
}

@media screen and (max-width: 480px) {
  .home__banner {
    height: 500px;
  }
  .home__banner__bg__resp {
    overflow: hidden;
    display: block;
  }
  .home__banner__bg__resp img {
    display: block;
    position: absolute;
    max-width: inherit;
    top: -125px;
    width: 700px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 480px) {
  .home__banner {
    height: 450px;
  }
}

.home__delivery {
  padding-top: 45px;
  padding-bottom: 100px;
}

.home__delivery__container {
  max-width: 1460px;
  position: relative;
}

.home-figure1 {
  position: absolute;
  top: -88%;
  left: -59.5%;
  pointer-events: none;
}

.home__delivery__title {
  margin-bottom: 40px;
}

.home__delivery__img__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.home__delivery__img {
  margin: 0;
  position: relative;
  width: 51.5%;
}

.home__delivery__img img {
  max-width: 100%;
  height: auto;
  display: block;
}

.home__delivery__img .home__delivery__image {
  position: relative;
  z-index: 2;
}

.home__delivery__img .home__delivery__mask {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
  max-width: 600px;
}

.home__delivery__title {
  text-align: center;
}

.home__delivery__title .title__g__text {
  margin-top: 5px;
}

.home__delivery__list {
  width: 24.9%;
  position: relative;
  right: 18%;
  text-align: left;
}

.home__delivery__list article {
  margin: 0;
  display: block;
  background: #fff;
  border-radius: 9px;
  -webkit-box-shadow: 0 9px 10px 0 rgba(215, 231, 245, 0.55);
  box-shadow: 0 9px 10px 0 rgba(215, 231, 245, 0.55);
  border: solid 1px #f0f5f7;
  margin-bottom: 35px;
  padding: 20px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.home__delivery__list article:last-child {
  margin-bottom: 0;
}

.home__delivery__list article p {
  margin: 0;
  font-family: 'ProximaNova-Light', sans-serif;
  font-size: 16px;
  color: #797f80;
  line-height: 1.35;
}

.home__delivery__list article strong {
  font-family: 'ProximaNova-Regular', sans-serif;
  font-size: 16px;
  color: #635daf;
}

.home__delivery__btn {
  text-align: center;
  margin-top: 45px;
}

@media screen and (max-width: 1400px) {
  .home-figure1 {
    left: -62.5%;
  }
}

@media screen and (max-width: 1300px) {
  .home__delivery__list {
    right: 10%;
    width: 30%;
  }
  .home-figure1 {
    left: -65%;
  }
}

@media screen and (max-width: 1200px) {
  .home-figure1 {
    left: -82%;
  }
}

@media screen and (max-width: 1024px) {
  .home-figure1 {
    left: -109%;
    top: -113%;
  }
}

@media screen and (max-width: 1024px) {
  .home__delivery__list {
    right: 0;
  }
  .home__delivery {
    padding-top: 61px;
  }
  .home__delivery__list {
    width: 40%;
  }
}

@media screen and (max-width: 960px) {
  .home-figure1 {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .home__delivery__img__list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .home__delivery__img {
    max-width: 536px;
    width: 100%;
    margin: auto;
  }
  .home__delivery__list {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    z-index: 5;
  }
  .home__delivery__list article {
    margin: 11px 2%;
    width: 46%;
  }
  .home__delivery__title .title__g__text {
    max-width: 263px;
    margin: auto;
    margin-top: 5px;
  }
  .home__delivery__title {
    margin-bottom: 55px;
  }
  .home__delivery__title .title__g__title h1,
  .home__delivery__title .title__g__title h2,
  .home__delivery__title .title__g__title h3,
  .home__delivery__title .title__g__title h4 {
    font-size: 30px;
  }
  .home__delivery__title .title__g__text {
    font-size: 18px;
  }
  .home__delivery__btn {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .home__delivery {
    padding-bottom: 66px;
  }
  .home__delivery__img .home__delivery__mask {
    max-width: 100%;
  }
  .home__delivery__list {
    padding-top: 0;
  }
  .home__delivery__list article {
    width: 100%;
    margin: 0;
    margin-bottom: 12px;
  }
  .home__delivery__list article:last-child {
    margin-bottom: 0;
  }
}

.home__plataforma__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 1352px;
  position: relative;
}

.home__plataforma__title {
  width: 34%;
  position: relative;
  left: 4%;
}

.home__plataforma__title .title__g__text {
  margin-top: 10px;
}

.home__plataforma__imgs {
  position: relative;
}

.home__plataforma__imgs {
  position: relative;
  font-size: 0;
  margin: 0;
  width: 60.276%;
}

.home__plataforma__imgs img {
  max-width: 100%;
  height: auto;
  display: block;
}

.home__plataforma__imgs .home__plataforma__image {
  position: relative;
  z-index: 2;
  top: -6px;
  left: -25px;
}

.home__plataforma__imgs .home__plataforma__mask {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}

@media screen and (max-width: 1024px) {
  .home__plataforma__title {
    left: 0;
  }
  .home__plataforma__title .title__g__title h1,
  .home__plataforma__title .title__g__title h2,
  .home__plataforma__title .title__g__title h3,
  .home__plataforma__title .title__g__title h4 {
    font-size: 35px;
  }
}

@media screen and (max-width: 768px) {
  .home__plataforma__title {
    width: 40%;
  }
  .home__plataforma__title .title__g__title h1,
  .home__plataforma__title .title__g__title h2,
  .home__plataforma__title .title__g__title h3,
  .home__plataforma__title .title__g__title h4 {
    font-size: 28px;
  }
  .home__plataforma__title .title__g__text {
    font-size: 18px;
  }
  .home__plataforma__imgs {
    width: 60%;
  }
}

@media screen and (max-width: 767px) {
  .home__plataforma__container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .home__plataforma__imgs {
    width: 100%;
  }
  .home__plataforma__title {
    width: 100%;
    margin-bottom: 30px;
  }
  .home__plataforma__imgs .home__plataforma__image {
    top: 0;
    left: 0;
  }
}

.home__list {
  padding-top: 50px;
  padding-bottom: 100px;
  position: relative;
  z-index: 3;
}

.home__list__container {
  max-width: 1220px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.home__list__container article {
  display: block;
  margin: 0;
  text-align: center;
  position: relative;
}

.home__list__container article:last-child span:before {
  display: none;
}

.home__list__container article span {
  position: relative;
  background: #fff;
  padding: 0 30px;
  display: inline-block;
}

.home__list__container article span:before {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -350%;
  width: 350%;
  height: 1px;
  background: #4cce92;
}

.home__list__container article span i {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 24px;
  font-family: 'ProximaNova-Regular', sans-serif;
  text-align: center;
  border-radius: 100%;
  background: #4cce92;
  color: #fff;
  font-style: normal;
}

.home__list__container article section {
  margin: 0;
  max-width: 290px;
  margin: auto;
  margin-top: 20px;
  font-size: 14px;
  font-family: 'ProximaNova-Light', sans-serif;
  color: #aeb4c3;
  line-height: 1.33;
}

@media screen and (max-width: 960px) {
  .home__list__container article span:before {
    width: 300%;
    right: -300%;
  }
}

@media screen and (max-width: 768px) {
  .home__list {
    padding-bottom: 140px;
  }
  .home__list__container article section {
    max-width: 172px;
  }
  .home__list__container article span:before {
    width: 250%;
    right: -250%;
  }
}

@media screen and (max-width: 767px) {
  .home__list {
    padding-bottom: 63px;
  }
  .home__list__container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 12% 0 7%;
  }
  .home__list__container article {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: left;
    padding-bottom: 32px;
  }
  .home__list__container article:last-child {
    padding-bottom: 0;
  }
  .home__list__container article > span {
    margin: 0;
    padding: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 18px;
  }
  .home__list__container article > span:before {
    top: inherit;
    -webkit-transform: inherit;
    transform: inherit;
    top: 100%;
    left: 0;
    right: 0;
    margin: auto;
    width: 1px;
    height: 200%;
  }
  .home__list__container article > section {
    width: calc(100% - 35px);
    padding-left: 30px;
    margin-top: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 100%;
  }
}

.home__trazabilidad {
  padding-top: 215px;
  position: relative;
}

.home__trazabilidad__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 1240px;
  text-align: left;
}

.home-figure2 {
  position: absolute;
  top: -79%;
  right: -86%;
  pointer-events: none;
}

.home__trazabilidad__imgs {
  position: relative;
  display: inline-block;
  width: 52%;
}

.htrazablidad__img1 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 705px;
  height: auto;
}

.htrazablidad__img2 {
  position: absolute;
  width: 12%;
  top: -55%;
  left: 8%;
  max-width: 100%;
  height: auto;
  display: block;
}

.htrazablidad__img3 {
  position: absolute;
  width: 39.34%;
  top: -28%;
  max-width: 100%;
  height: auto;
  display: block;
  z-index: 3;
}

.htrazablidad__img4 {
  position: absolute;
  width: 47.5%;
  top: -70%;
  right: 6%;
  max-width: 100%;
  height: auto;
  display: block;
}

.home__trazabilidad__cel {
  position: relative;
  display: inline-block;
  width: 100%;
}

.htrazablidad__img5 {
  height: auto;
  width: 100%;
  display: block;
}

.htrazablidad__img6 {
  position: absolute;
  width: 13%;
  left: 41%;
  top: 12%;
  max-width: 100%;
  height: auto;
  display: block;
}

.htrazablidad__img7 {
  position: absolute;
  width: 6%;
  right: 8%;
  bottom: 34%;
  max-width: 100%;
  height: auto;
  display: block;
}

.home__trazabilidad__info {
  width: 36%;
  position: relative;
  bottom: 115px;
  right: -2%;
}

.home__trazabilidad__info .title__g__text {
  margin-top: 15px;
}

.home__trazabilidad__text {
  background: #fff;
  padding: 40px 30px 30px 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 1.22;
  font-size: 18px;
  color: #aeb4c3;
  font-family: 'ProximaNova-Thin', sans-serif;
  border-radius: 9px;
  -webkit-box-shadow: 0 9px 13px 0 rgba(215, 231, 245, 0.55);
  box-shadow: 0 9px 13px 0 rgba(215, 231, 245, 0.55);
  border: solid 1px #ededed;
}

.home__trazabilidad__text.bottomt {
  display: none;
}

@media screen and (max-width: 1300px) {
  .home__trazabilidad__info {
    right: 0;
  }
}

@media screen and (max-width: 1200px) {
  .home__trazabilidad {
    padding-top: 10%;
  }
  .htrazablidad__img1 {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .home__trazabilidad__info {
    width: 40%;
  }
  .home__trazabilidad__container {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .home-figure2 {
    right: -100%;
  }
}

@media screen and (max-width: 1020px) {
  .home-figure2 {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .home__trazabilidad__text {
    display: none;
  }
  .home__trazabilidad__info .title__g__title h1,
  .home__trazabilidad__info .title__g__title h2,
  .home__trazabilidad__info .title__g__title h3,
  .home__trazabilidad__info .title__g__title h4 {
    font-size: 28px;
  }
  .home__trazabilidad__info .title__g__text {
    font-size: 18px;
  }
  .home__trazabilidad__info {
    width: 45%;
  }
  .home__trazabilidad__imgs {
    width: 55%;
  }
  .home__trazabilidad {
    position: relative;
    padding-bottom: 120px;
  }
  .home__trazabilidad__text.bottomt {
    display: block;
    position: absolute;
    bottom: -49%;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 493px;
    width: 100%;
    padding: 28px 25px 12px 25px;
  }
  .home__trazabilidad__info {
    bottom: 43px;
  }
}

@media screen and (max-width: 767px) {
  .home__trazabilidad__info {
    bottom: 0;
  }
  .home__trazabilidad {
    padding-top: 0;
    padding-bottom: 0;
  }
  .home__trazabilidad__container {
    padding-bottom: 0;
  }
  .home__trazabilidad__container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .home__trazabilidad__imgs {
    width: 100%;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .home__trazabilidad__info {
    padding-bottom: 45%;
  }
  .home__trazabilidad__info {
    width: 100%;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .home__trazabilidad__text.bottomt {
    bottom: 0;
    padding: 19px 26px 8px 27px;
    font-size: 14px;
    max-width: 309px;
    width: 90%;
    margin: auto;
    position: static;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    margin-top: -6%;
  }
}

.home__automatiza {
  padding-top: 151px;
  position: relative;
}

.home__automatiza__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}

.home-figure3 {
  position: absolute;
  top: -10%;
  left: -76%;
  pointer-events: none;
}

.home__automatiza__title .title__g__text {
  margin-top: 5px;
}

.home__automatiza__logos__txt {
  margin-top: 30px;
  width: calc(100% - 80px);
  padding-left: 30px;
  margin-right: 0;
  margin-left: auto;
}

.home__automatiza__logos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.home__automatiza__logos figure {
  margin: 0;
  font-size: 0;
  border-radius: 9px;
  background: #fff;
  max-width: 140px;
  width: 100%;
  padding: 10px 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 15px;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 9px 13px 0 rgba(215, 231, 245, 0.55);
  box-shadow: 0 9px 13px 0 rgba(215, 231, 245, 0.55);
  border: solid 1px #f0f5f7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.home__automatiza__logos figure:nth-child(3) {
  margin-left: 60px;
}

.home__automatiza__logos figure img {
  max-width: calc(100% / 1);
  height: auto;
  display: block;
  margin: auto;
}

.home__automatiza__imgs__txt {
  width: 57.5%;
}

.home__automatiza__imgs__txt figure {
  margin: 0;
  font-size: 0;
}

.home__automatiza__imgs__txt figure img {
  max-width: 100%;
  height: auto;
  display: block;
}

.home__automatiza__imgs__txt figcaption {
  margin: 0;
  background: #fff;
  padding: 40px 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 1.22;
  font-size: 18px;
  color: #323e48;
  font-family: 'ProximaNova-Thin', sans-serif;
  box-sizing: border-box;
  border-radius: 14.5px;
  -webkit-box-shadow: 0 9px 13px 0 rgba(215, 231, 245, 0.55);
  box-shadow: 0 9px 13px 0 rgba(215, 231, 245, 0.55);
  border: solid 1px #f0f5f7;
  margin-top: -30px;
  position: relative;
  z-index: 5;
  max-width: 454px;
  margin: auto;
  margin-top: -58px;
  text-align: left;
}

.home__automatiza__info {
  width: 42%;
}

.home__automatiza__txt {
  font-size: 16px;
  font-family: 'ProximaNova-Light', sans-serif;
  color: #797f80;
  text-align: center;
  margin-top: 20px;
}

.home__automatiza__txt p {
  margin: 0;
}

.home__automatiza__logos__txt.bottom_a {
  display: none;
}

@media screen and (max-width: 1100px) {
  .home__automatiza__logos figure {
    width: 35%;
    max-width: 100%;
  }
  .home__automatiza__logos figure:nth-child(3) {
    margin-left: 45px;
  }
  .home-figure3 {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .home-figure3 {
    top: 20%;
    left: -83%;
  }
  .home__automatiza__title .title__g__text {
    font-size: 18px;
  }
  .home__automatiza__title .title__g__title h1,
  .home__automatiza__title .title__g__title h2,
  .home__automatiza__title .title__g__title h3,
  .home__automatiza__title .title__g__title h4 {
    font-size: 28px;
  }
  .home__automatiza__logos figure {
    width: 32%;
    max-width: 100%;
  }
}

@media screen and (max-width: 1020px) {
  .home-figure3 {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .home__automatiza__imgs__txt {
    width: 60%;
  }
  .home__automatiza__info {
    width: 40%;
  }
  .home__automatiza__imgs__txt figcaption {
    max-width: 318px;
    padding: 21px 13px 10px 21px;
    font-size: 14px;
  }
  .home__automatiza__logos__txt {
    width: calc(100% - 55px);
    padding-left: 0;
  }
  .home__automatiza {
    padding-top: 90px;
  }
}

@media screen and (max-width: 768px) {
  .home__automatiza__container {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    position: relative;
  }
  .home__automatiza__info {
    position: absolute;
    top: 55px;
    left: 20px;
    width: 47%;
    z-index: 3;
  }
  .home__automatiza__imgs__txt {
    width: 59.216%;
  }
  .home__automatiza__logos figure:nth-child(3) {
    margin-left: 27px;
  }
  .home__automatiza__title .title__g__title h1,
  .home__automatiza__title .title__g__title h2,
  .home__automatiza__title .title__g__title h3,
  .home__automatiza__title .title__g__title h4 {
    line-height: 1;
  }
  .home__automatiza__logos__txt {
    margin-top: 15px;
    position: relative;
    left: 15px;
  }
  .home__automatiza__logos figure {
    max-width: 114px;
    width: 100%;
    margin: 0 5px;
    margin-bottom: 10px;
  }
  .home__automatiza__title {
    width: 78%;
  }
  .home__automatiza__imgs__txt figcaption {
    margin-right: 35px;
  }
}

@media screen and (max-width: 767px) {
  .home__automatiza__container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .home__automatiza__info {
    width: 100%;
  }
  .home__automatiza__imgs__txt {
    width: 100%;
    padding-top: 14px;
  }
  .home__automatiza {
    padding-top: 63px;
  }
  .home__automatiza__info {
    position: static;
  }
  .home__automatiza__title {
    width: 100%;
  }
  .home__automatiza__imgs__txt figure {
    padding: 0 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .home__automatiza__imgs__txt figcaption {
    width: 100%;
    margin: auto;
    margin-top: -6%;
  }
  .home__automatiza__logos__txt {
    display: none;
  }
  .home__automatiza__logos__txt.bottom_a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    position: static;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .home__automatiza__logos__txt.bottom_a .home__automatiza__txt {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    line-height: 1.35;
    font-size: 16px;
    color: #797f80;
    font-style: italic;
    margin-bottom: 20px;
    width: 100%;
  }
  .home__automatiza__logos__txt.bottom_a .home__automatiza__logos {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    width: 100%;
  }
  .home__automatiza__logos__txt.bottom_a .home__automatiza__logos figure {
    max-width: 100%;
    width: 45%;
    padding: 10px 0;
    margin: 0 2% 10px 2% !important;
  }
  .home__automatiza__logos__txt.bottom_a .home__automatiza__logos figure img {
    max-width: calc(100% / 2);
  }
}

.home__logos {
  padding-top: 121px;
}

.home__logos__container {
  max-width: 1014px;
  position: relative;
}

.home-figure4 {
  position: absolute;
  top: 41%;
  right: -98%;
  pointer-events: none;
}

.home__logos__title {
  max-width: 540px;
  margin: auto;
  width: 100%;
  text-align: center;
}

.home__logos__list__slid {
  padding-bottom: 40px;
}

.home__logos__list {
  margin-top: 40px;
}

.home__logos__list figure {
  margin: 0;
  padding: 0;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
}

.home__logos__list figure img {
  max-width: 70%;
  height: auto;
  display: block;
  filter: grayscale(100%);
}

.home__logos__list .dots__g__slider .slick-dots button {
  width: 10px;
  height: 10px;
  background: transparent;
  border: 1px solid #aab9c1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  opacity: 1;
}

.home__logos__list .dots__g__slider .slick-dots li.slick-active button {
  background: #aab9c1;
}

@media screen and (max-width: 1024px) {
  .home__logos {
    padding-top: 100px;
  }
  .home__logos__list figure {
    width: 25%;
  }
}

@media screen and (max-width: 1020px) {
  .home-figure4 {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .home__logos__title .title__g__title h1,
  .home__logos__title .title__g__title h2,
  .home__logos__title .title__g__title h3,
  .home__logos__title .title__g__title h4 {
    font-size: 28px;
  }
}

@media screen and (max-width: 767px) {
  .home__logos {
    padding-top: 65px;
  }
  .home__logos__list__slid {
    padding-bottom: 25px;
  }
}

.home__foot {
  padding-top: 165px;
  padding-bottom: 148px;
}

.home__foot__container {
  max-width: 600px;
  text-align: center;
}

.home__foot__title .title__g__text {
  margin-top: 15px;
}

.home__foot__btns {
  margin-top: 48px;
}

.home__foot__btns .button__g {
  margin: 10px 17px;
}

@media screen and (max-width: 1024px) {
  .home__foot {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 768px) {
  .home__foot__title .title__g__title h1,
  .home__foot__title .title__g__title h2,
  .home__foot__title .title__g__title h3,
  .home__foot__title .title__g__title h4 {
    font-size: 30px;
  }
  .home__foot__title .title__g__text {
    font-size: 20px;
  }
}

@media screen and (max-width: 767px) {
  .home__foot__title .title__g__text {
    font-size: 18px;
  }
  .home__foot {
    padding-top: 51px;
    padding-bottom: 54px;
  }
}

.about__us {
  padding-top: 69px;
  padding-bottom: 69px;
}

.about__us__container {
  max-width: 1064px;
}

.about__us__text {
  max-width: 900px;
  margin: auto;
  width: 100%;
  font-size: 15px;
  font-family: 'ProximaNova-Light', sans-serif;
  color: #797f80;
  text-align: center;
}

.about__us__list {
  margin-top: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.about__us__list > article {
  width: 47%;
  min-height: 360px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.about__us__list > article:first-child {
  background: #323e48;
}

.about__us__list > article:last-child {
  background: #edeffa;
}

.about__us__list > article > div {
  max-width: 350px;
  width: 100%;
  margin: auto;
}

.about__us__list > article > div > figure {
  width: 38px;
  height: 38px;
  margin: 0;
  margin: 0 auto;
  margin-bottom: 27px;
}

.about__us__list > article > div > figure > img {
  display: block;
  height: auto;
  max-width: 100%;
}

.about__us__list > article > div > figcaption {
  text-align: center;
}

.about__us__list > article > div > figcaption > h3 {
  color: #fff;
  font-size: 18px;
  font-family: 'ProximaNova-Thin', sans-serif;
  margin: 0;
  margin-bottom: 13px;
  letter-spacing: 0.4px;
}

.about__us__list > article > div > figcaption > section {
  color: #fff;
  font-family: 'ProximaNova-Light', sans-serif;
  font-size: 15px;
  line-height: 1.47;
}

@media screen and (max-width: 767px) {
  .about__us__list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .about__us__list > article {
    width: 100%;
    min-height: auto;
    margin-bottom: 30px;
  }
  .about__us__list > article:last-child {
    margin-bottom: 0;
  }
}

.about__us__info__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 1100px;
}

.about__us__info__img {
  width: 444px;
  height: 444px;
}

.about__us__info__img > img {
  max-width: 100%;
  height: auto;
  display: block;
}

.about__us__info__info {
  width: calc(100% - 444px);
}

.about__us__info__info__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 53px;
}

.about__us__info__info__list > article > figure {
  height: 54px;
  width: 54px;
}

.about__us__info__info__list > article > figure > img {
  max-width: 100%;
  height: auto;
  display: block;
}

.about__us__info__info__list > article > figcaption {
  margin-top: 32px;
}

.about__us__info__info__list > article > figcaption > h3 {
  font-size: 14px;
  font-family: 'ProximaNova-Thin', sans-serif;
  color: #4cce92;
  margin: 0;
}

.about__us__info__info__text {
  margin-top: 31px;
  font-size: 15px;
  color: #797f80;
  font-family: 'ProximaNova-Light', sans-serif;
  text-align: center;
}

@media screen and (max-width: 1020px) {
  .about__us__info__container {
    text-align: center;
  }
  .about__us__info__img {
    display: none;
  }
  .about__us__info__info {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .about__us__info__info__list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .about__us__info__info__list article {
    width: 100%;
    margin-bottom: 20px;
  }
  .about__us__info__info__list article:last-child {
    margin-bottom: 0;
  }
}

.about__us__clients {
  padding-top: 104px;
  padding-bottom: 105px;
}

.about__us__clients__title__arrows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}

.about__us__clients__slider__itm {
  padding: 20px 14px;
}

.about__us__clients__slider__itm:hover > article {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.about__us__clients__slider__itm > article {
  padding: 35px 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.about__us__clients__slider__itm > article > figure {
  margin: 0 auto;
  margin-bottom: 25px;
}

.about__us__clients__slider__itm > article > figure > img {
  max-width: 100%;
  height: auto;
  display: block;
}

.about__us__clients__slider__itm > article > figcaption {
  text-align: center;
  max-width: 210px;
  width: 100%;
  margin: auto;
  line-height: 1.43;
  color: #797f80;
  font-size: 14px;
}

.about__us__clients__arrows.bottom {
  display: none;
}

@media screen and (max-width: 767px) {
  .about__us__clients__arrows {
    display: none;
  }
  .about__us__clients__title__arrows {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .about__us__clients__arrows.bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.register__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.register__block__aside {
  width: 278px;
  margin: 0;
  background: #4cce92;
  padding: 0 35px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.register__block__img {
  max-width: 189px;
  width: 100%;
  margin: auto;
  display: block;
  margin-top: 33px;
  font-size: 0;
}

.register__block__img img {
  max-width: 100%;
  height: auto;
  display: block;
}

.register__block__list {
  margin-top: 148px;
}

.register__block__list > ul > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 41px;
  position: relative;
}

.register__block__list > ul > li:last-child {
  margin-bottom: 0;
}

.register__block__list > ul > li:last-child > span:after {
  display: none;
}

.register__block__list > ul > li.active > span > i {
  background: #fff;
  color: #4cce92;
  font-family: 'ProximaNova-Bold', sans-serif;
}

.register__block__list > ul > li.active > span:before {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}

.register__block__list > ul > li > span {
  position: relative;
  margin: 0;
  display: inline-block;
}

.register__block__list > ul > li > span > i {
  display: block;
  width: 46px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  position: relative;
  font-style: normal;
  background: #a3f5af;
  color: #4cce92;
  border-radius: 100%;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  font-size: 25px;
  font-family: 'ProximaNova-Thin', sans-serif;
  z-index: 2;
}

.register__block__list > ul > li > span:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -100%;
  margin: auto;
  width: 1px;
  height: 100%;
  background: #a3f5af;
}

.register__block__list > ul > li > span:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 65px;
  height: 65px;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  background: #a3f5af;
  border-radius: 100%;
  opacity: 0;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 1;
}

.register__block__list > ul > li > p {
  margin: 0;
  margin-left: 24px;
  font-size: 15px;
  font-family: 'ProximaNova-Thin', sans-serif;
  color: #a3f5af;
}

.register__block__content {
  width: calc(100% - 278px);
  background: #f8f9fb;
  padding-bottom: 224px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.register__block__content__btn {
  text-align: right;
  max-width: 960px;
  margin-top: 43px;
}

.btn__back {
  margin: 0;
  position: relative;
  display: inline-block;
  padding-left: 40px;
  font-family: 'ProximaNova-Thin', sans-serif;
  font-size: 15px;
  color: #797f80;
}

.btn__back i {
  font-style: normal;
  color: #4cce92;
  font-family: 'ProximaNova-Thin', sans-serif;
  font-size: 15px;
  margin-right: 7px;
}

.register__block__content__cnt {
  max-width: 796px;
  padding-top: 126px;
}

.register__block__content__title {
  max-width: 470px;
  width: 100%;
  margin: auto;
  text-align: center;
}

.register__block__content__title .title__g__title h1,
.register__block__content__title .title__g__title h2,
.register__block__content__title .title__g__title h3,
.register__block__content__title .title__g__title h4,
.register__block__content__title .title__g__title h5 {
  line-height: 1.18;
}

.register__block__content__title .title__g__text {
  margin-top: 17px;
  line-height: 1.84;
}

.register__block__form {
  padding-top: 108px;
}

.form__block__check--registro {
  background: #edeffa;
  padding: 14px 22px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.form__block__button--registro {
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .register__block__content {
    padding: 0 30px 100px 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .register__block__content__cnt {
    padding-top: 70px;
  }
  .register__block__form {
    padding-top: 50px;
  }
}

@media screen and (max-width: 960px) {
  .register__block__aside {
    width: 220px;
    padding: 0 25px;
  }
  .register__block__content {
    width: calc(100% - 220px);
  }
  .register__block__list {
    margin-top: 100px;
  }
  .register__block .input__g--three-columns .input__g {
    width: 100%;
    margin-right: 0 !important;
  }
  .register__block .input__g--three-columns .input__g:nth-child(even) {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 767px) {
  .register__block__aside {
    width: 100%;
    padding: 40px 25px;
  }
  .register__block__content {
    width: 100%;
  }
  .register__block__img {
    margin-top: 0;
  }
  .register__block__list {
    margin-top: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .register__block__list > ul {
    max-width: 200px;
    margin: auto;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .register__block__list > ul > li {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .register__block__content {
    padding: 0 10px;
  }
}

.reg__personcomp__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.reg__personcomp__aside {
  margin: 0;
  background: #4cce92;
  padding: 34px 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 639px;
  overflow: hidden;
  position: relative;
}

.reg__personcomp__aside__cnt {
  max-width: 463px;
  height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-bottom: 75%;
  margin-bottom: 40px;
}

.reg__personcomp__aside__img {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 75%;
  font-size: 0;
  margin: 0;
  left: 0;
}

.reg__personcomp__aside__img img {
  max-width: 100%;
  height: auto;
  display: block;
}

.reg__personcomp__aside__img .reg__personcomp--person {
  position: absolute;
  z-index: 10;
  width: 26.919%;
  right: 25%;
  top: 26%;
}

.reg__personcomp__aside__img .reg__personcomp--arbol {
  position: absolute;
  bottom: 16%;
  width: 14.712%;
  left: 32%;
  z-index: 3;
}

.reg__personcomp__aside__img .reg__personcomp--laptop {
  position: absolute;
  top: 0%;
  right: 9%;
  width: 42.412%;
}

.reg__personcomp__aside__img .reg__personcomp--figure {
  position: absolute;
  bottom: -21%;
  width: 76.213%;
  left: 7%;
}

.reg__personcomp__img {
  max-width: 175px;
  width: 100%;
  margin-left: 0;
  margin-right: auto;
  display: block;
  font-size: 0;
}

.reg__personcomp__img img {
  max-width: 100%;
  height: auto;
  display: block;
}

.reg__personcomp__title {
  padding-top: 75px;
  text-align: left;
}

.reg__personcomp__title .title__g__text {
  margin-top: 49px;
  line-height: 1.47;
  text-align: justify;
}

.reg__personcomp__content {
  width: calc(100% - 639px);
  background: #f8f9fb;
  padding-top: 65px;
  padding-bottom: 65px;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.reg__personcomp__aside__in {
  overflow: hidden;
  height: 100%;
}

.reg__personcomp__content__cnt {
  max-width: 540px;
}

.reg__personcomp__links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.reg__personcomp__link {
  display: inline-block;
  position: relative;
  padding: 23px 20px 20px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  text-align: center;
  border-radius: 8.7px;
  border: solid 1px #fff;
  max-width: 173px;
  width: 100%;
  margin: 0 35px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
}

.reg__personcomp__link:nth-child(1) > span {
  background: #4cce92;
}

.reg__personcomp__link:nth-child(1) > figure {
  background: rgba(183, 235, 211, 0.27);
}

.reg__personcomp__link:nth-child(1) > figure > i {
  font-size: 23px;
  color: #4cce92;
}

.reg__personcomp__link:nth-child(1) > figure > i:before {
  color: #4cce92;
}

.reg__personcomp__link:nth-child(1):hover {
  border: 1px solid #4cce92;
}

.reg__personcomp__link:nth-child(1):hover > h3 {
  color: #4cce92;
}

.reg__personcomp__link:nth-child(1).active {
  border: 1px solid #4cce92;
}

.reg__personcomp__link:nth-child(1).active > span {
  opacity: 1;
}

.reg__personcomp__link:nth-child(1).active > h3 {
  color: #4cce92;
}

.reg__personcomp__link:nth-child(2) > span {
  background: #635daf;
}

.reg__personcomp__link:nth-child(2) > figure > i:before {
  color: #635daf;
}

.reg__personcomp__link:nth-child(2) > h3 {
  color: #a1a6d4;
}

.reg__personcomp__link:nth-child(2):hover {
  border: 1px solid #635daf;
}

.reg__personcomp__link:nth-child(2):hover > h3 {
  color: #635daf;
}

.reg__personcomp__link:nth-child(2).active {
  border: 1px solid #635daf;
}

.reg__personcomp__link:nth-child(2).active > span {
  opacity: 1;
}

.reg__personcomp__link:nth-child(2).active > h3 {
  color: #635daf;
}

.reg__personcomp__link > span {
  display: inline-block;
  width: 29px;
  height: 29px;
  line-height: 29px;
  text-align: center;
  border-radius: 100%;
  color: #fff;
  font-size: 15px;
  position: absolute;
  top: -14.5px;
  right: -14.5px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  opacity: 0;
}

.reg__personcomp__link > span:before {
  color: #fff;
}

.reg__personcomp__link > figure {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  width: 54px;
  height: 54px;
  line-height: 45px;
  margin: auto;
  border-radius: 100%;
  background: #ecedf6;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.reg__personcomp__link > figure > i {
  color: #4cce92;
  font-style: normal;
  margin: auto;
  font-size: 20px;
  line-height: 54px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.reg__personcomp__link > figure > i:before {
  color: #4cce92;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.reg__personcomp__link > h3 {
  font-family: 'ProximaNova-Thin', sans-serif;
  color: #4cce92;
  font-size: 15px;
  margin: 0;
  margin-top: 16px;
  line-height: 1.38;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.reg__personcomp__form {
  margin-top: 73px;
}

.reg__personcomp__form > p {
  font-family: 'ProximaNova-Thin', sans-serif;
  color: #323e48;
  margin: 0;
  font-size: 15px;
  margin-bottom: 20px;
  text-align: left;
}

.reg__personcomp__form .form__block__text {
  line-height: 1.2;
  font-family: 'ProximaNova-Thin', sans-serif;
  color: #323e48;
  font-size: 12px;
  max-width: 245px;
  width: 100%;
  margin-left: 0;
  margin-right: auto;
  margin-bottom: 39px;
  margin-top: 10px;
  order: 2;
  text-align: left;
}

@media screen and (max-width: 1450px) {
  .reg__personcomp {
    height: auto;
  }
  .reg__personcomp__title {
    padding-top: 60px;
  }
  .reg__personcomp__aside {
    width: 500px;
    height: auto;
  }
  .reg__personcomp__content {
    width: calc(100% - 500px);
    padding-top: 65px;
    height: auto;
    overflow: inherit;
  }
  .reg__personcomp__link {
    margin: 0 20px;
  }
}

@media screen and (max-width: 1200px) {
  .reg__personcomp__aside {
    width: 400px;
  }
  .reg__personcomp__content {
    width: calc(100% - 400px);
  }
}

@media screen and (max-width: 1020px) {
  .reg__personcomp__aside {
    width: 100%;
  }
  .reg__personcomp__content {
    width: 100%;
  }
  .reg__personcomp__aside__img {
    display: none;
  }
  .reg__personcomp__aside {
    padding: 34px 20px;
  }
  .reg__personcomp__title {
    padding-top: 50px;
  }
  .reg__personcomp__aside__cnt {
    padding-bottom: 0;
  }
  .reg__personcomp--emp .reg__personcomp__aside__cnt {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .reg__personcomp__img {
    max-width: 160px;
  }
}

@media screen and (max-width: 767px) {
  .reg__personcomp__form .input__g--two-columns .input__g {
    width: 100%;
    margin-right: 0 !important;
  }
  .reg__personcomp__img {
    max-width: 150px;
  }
}

.reg__personcomp__person {
  position: relative;
  bottom: 8px;
  z-index: 3;
}

.reg__personcomp__nubes {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
}

.reg__personcomp--persona1 {
  left: 0;
  position: absolute;
  bottom: 20%;
  z-index: 1;
}

.reg__personcomp--persona3 {
  position: absolute;
  bottom: 8%;
  width: 53.5%;
  left: 10%;
  z-index: 4;
}

.reg__personcomp--persona4 {
  position: absolute;
  bottom: 8%;
  right: 10%;
  width: 21.3%;
  z-index: 3;
}

.reg__personcomp--persona5 {
  position: absolute;
  bottom: 10%;
  right: 23%;
  z-index: 5;
  width: 6.5%;
}

.reg__personcomp__imagesall {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.reg__personcomp--persona2 {
  position: absolute;
  bottom: 9%;
  right: 24%;
  z-index: 1;
  width: 8.766%;
}

.reg__personcomp--persona6 {
  bottom: 10%;
  right: 28%;
  position: absolute;
  width: 10%;
  z-index: 3;
}

.reg__personcomp__aside--per {
  padding-left: 0;
  padding-right: 0;
}

.reg__personcomp--nube1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 12.5%;
  z-index: 0;
}

.reg__personcomp--nube2 {
  position: absolute;
  top: 18%;
  width: 10%;
  left: 26%;
  z-index: 0;
}

.reg__personcomp--nube3 {
  position: absolute;
  top: 37%;
  right: 37%;
  width: 12.5%;
  z-index: 0;
}

.reg__personcomp--nube5 {
  position: absolute;
  top: 10%;
  top: 15%;
  right: 150px;
  width: 12.5%;
  z-index: 0;
}

.reg__personcomp--nube6 {
  position: absolute;
  top: 37%;
  right: 0;
  width: 10%;
  z-index: 0;
}

.reg__personcomp--nube7 {
  position: absolute;
  top: 20%;
  right: -40%;
  width: 12.5%;
  z-index: 0;
}

.reg__personcomp--nube8 {
  position: absolute;
  top: 5%;
  right: -30%;
  width: 10%;
  z-index: 0;
}

@-webkit-keyframes move-nube {
  0% {
    -webkit-transform: translateX(-200px);
    transform: translateX(-200px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes move-nube {
  0% {
    -webkit-transform: translateX(-200px);
    transform: translateX(-200px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes move-nube2 {
  0% {
    -webkit-transform: translateX(200px);
    transform: translateX(200px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes move-nube2 {
  0% {
    -webkit-transform: translateX(200px);
    transform: translateX(200px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.reg__personcomp--nube1 {
  -webkit-animation: move-nube 4.1s linear alternate forwards;
  animation: move-nube 4.1s linear alternate forwards;
}

.reg__personcomp--nube2 {
  -webkit-animation: move-nube2 5.5s linear alternate forwards;
  animation: move-nube2 5.5s linear alternate forwards;
}

.reg__personcomp--nube3 {
  -webkit-animation: move-nube 5.1s linear alternate forwards;
  animation: move-nube 5.1s linear alternate forwards;
}

.reg__personcomp--nube4 {
  -webkit-animation: move-nube2 4.7s linear alternate forwards;
  animation: move-nube2 4.7s linear alternate forwards;
}

.reg__personcomp--nube5 {
  -webkit-animation: move-nube 3.1s linear alternate forwards;
  animation: move-nube 3.1s linear alternate forwards;
}

.reg__personcomp--nube6 {
  -webkit-animation: move-nube 4.5s linear alternate forwards;
  animation: move-nube 4.5s linear alternate forwards;
}

.reg__personcomp--nube7 {
  -webkit-animation: move-nube2 3.5s linear alternate forwards;
  animation: move-nube2 3.5s linear alternate forwards;
}

.reg__personcomp--nube8 {
  -webkit-animation: move-nube 4.4s linear alternate forwards;
  animation: move-nube 4.4s linear alternate forwards;
}

@media screen and (max-width: 1024px) {
  .reg__personcomp__plants {
    display: none;
  }
}
