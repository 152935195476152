.u-hidden {
  display: none !important;
}

.u-block {
  display: block !important;
}

.u-inline-block {
  display: inline-block;
}

.u-textCenter {
  text-align: center !important;
}

.u-textLeft {
  text-align: left !important;
}

.u-textRight {
  text-align: right !important;
}

.u-floatLeft {
  float: left !important;
}

.u-floatRight {
  float: right !important;
}

.u-cf {
  overflow: auto;
}

.u-normalWeight {
  font-weight: normal;
}

.u-boldWeight {
  font-weight: bold;
}

.u-centerBlock {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.u-mt5 {
  margin-top: 5px !important;
}
.u-mt10 {
  margin-top: 10px !important;
}
.u-mt20 {
  margin-top: 20px !important;
}
.u-mt30 {
  margin-top: 30px !important;
}
.u-mb20 {
  margin-bottom: 20px !important;
}
.u-mb30 {
  margin-bottom: 30px !important;
}

.u-mt40 {
  margin-top: 40px !important;
}

.u-mb40 {
  margin-bottom: 40px !important;
}

.u-mt60 {
  margin-top: 60px !important;
}

.u-mb60 {
  margin-bottom: 60px !important;
}
.u-flex {
  display: flex;
}
.u-flex-between {
  justify-content: space-between;
}
.u-flex-center {
  display: flex;
  align-items: center;
}
.u-flex-around {
  justify-content: space-around;
}
.u-flex-evenly {
  justify-content: space-evenly;
}

.u-flex-column {
  flex-flow: column;
}

.u-flex-row {
  flex-flow: row;
}
.u-flex-wrap {
  flex-wrap: wrap;
}

.u-position-fixed {
  position: fixed !important;
}
.u-fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.u-padding-10 {
  padding: 10px;
}

.u-padding-20 {
  padding: 20px;
}
.u-p-lr-40 {
  padding: 20px 40px;
}

.u-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.u-relative {
  position: relative;
}
.u-absolute {
  position: absolute;
}

.e-orderForm-delete {
  top: 60px;
  position: absolute;
  background: transparent;
  border: none;
  right: 0;
  cursor: pointer;
}

.e-box-material {
  color: #56606d;

  border: 1px solid rgb(218, 220, 224, 0.5);
  border-radius: 8px;
  background: #fff;
  transition: box-shadow 0.3s;
}
.e-box-material:focus-within,
.e-box-material:hover {
  box-shadow: 0 14px 28px rgba(218, 220, 224, 0.12),
    0 10px 10px rgba(218, 220, 224, 0.2);
}
.e-box-material-error {
  box-shadow: 0.5em 0.5em 4em rgba(255, 0, 0, 0.15),
    0 0 10px rgba(226, 0, 0, 0.15);
}
.e-button-material {
  color: white;
  background: #635dc2;
  border: none;
  border-radius: 50%;
  padding: 0.8em 0.92em 0.74em;
  cursor: pointer;
  font-size: 1em;
}
.e-container-buttons {
  position: absolute;
  bottom: -80px;
  display: flex;
  align-items: center;
  right: 0;
  min-width: 150px;
  justify-content: space-between;
  background-color: transparent;
}

.c-container-pointForm {
  margin-bottom: 5em;
}
.m-title-pointForm {
  display: flex;
  align-items: center;
}

.c-container-groupButton {
  position: fixed;
  bottom: 0;
  background: transparent;
  width: 50%;
  left: 0;
}

.u-align-left {
  text-align: left;
}

.u-align-center {
  text-align: center;
  float: none;
}

.u-mr-5 {
  margin-right: 5px;
}

.u-mr-10 {
  margin-right: 10px !important;
}

.u-ml-10 {
  margin-left: 10px;
}

.u-container-wrap {
  display: flex;
  flex-wrap: wrap;
}
.u-element-wrap {
  display: flex;
  flex-direction: column;
  padding: 0.2em 0.5em;
  width: 50%;
}

.u-icon-right {
  color: black;
  float: right;
  cursor: pointer;
}

.u-combobox-selected {
  color: #5fa0e8;
}

@media screen and (max-width: 780px) {
  .u-p-lr-40 {
    padding: 20px;
  }
}
.u-align-column {
  flex-direction: column;
}

.u-margin-1em {
  margin: 0 1em !important;
}

.u-margin-10 {
  margin: 10px;
}
